import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import styles from "../styles/Menu.module.css";
import Link from "next/link";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { Element, animateScroll as scroll, scroller } from "react-scroll";
import { i18n } from "next-i18next";

import { isMobile } from "react-device-detect";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

export default forwardRef(function Menu(props: any, ref: any) {
  const refContainer = useRef<HTMLDivElement>(null);

  const refTitle = useRef<HTMLHeadingElement>(null);
  const refList = useRef<HTMLUListElement>(null);

  let _isBottom = false;

  useEffect(() => {
    gsap.to(refContainer.current, {
      scrollTrigger: {
        scroller: "#main",
        //scroller: "#menu",
        id: "menu",
        //trigger: "body",
        //fastScrollEnd: true,

        /*
        onUpdate: (self) => {
          if (self != null) {
            if (self.direction === -1) {
              gsap.to(refContainer.current, {
                yPercent: 0,
                duration: 0.3,
              });
            } else {
              gsap.to(refContainer.current, {
                yPercent: -200,
                duration: 0.3,
              });
            }
          }

          if (self.progress > 0.999) {
            if (!_isBottom) {
              gsap.to(refContainer.current, {
                yPercent: 0,
                duration: 0.8,
                ease: "Expo.easeOut",
              });
              _isBottom = true;
            }
          } else {
            _isBottom = false;
          }
        },
        */

        onLeave: () => {
          /*
          gsap.to(refContainer.current, {
            yPercent: 0,
            duration: 0.8,
            ease: "Expo.easeOut",
          });
          */
        },
      },
    });

    if (isMobile) {
      if (refContainer.current != null) {
        refContainer.current.style.visibility = "hidden";
      }
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      showMenu(): void {
        gsap.killTweensOf(refContainer.current);

        gsap.to(refContainer.current, {
          yPercent: 0,
          duration: 0.8,
          ease: "Expo.easeOut",
        });
      },

      initMenu(delay: number) {},
    };
  });

  function animLine(e: any, isEnter: boolean) {
    gsap.killTweensOf(e);

    if (isEnter) {
      gsap.set(e, { width: 0 });

      gsap.to(e, {
        duration: 0.4,
        ease: "Power4.easeOut",
        width: "100%",
      });
    } else {
      gsap.set(e, { width: "100%" });

      gsap.to(e, {
        duration: 0.3,
        ease: "Circ.easeOut",
        width: "0%",
      });
    }
  }

  function animLang(e: any, isEnter: boolean) {
    gsap.killTweensOf(e);
    gsap.killTweensOf(e.childNodes[1]);

    if (isEnter) {
      gsap.set(e.childNodes[1], { zIndex: -1 });

      gsap.to(e, {
        duration: 0.4,
        ease: "Power4.easeOut",
        color: "#000",
      });

      gsap.set(e.childNodes[1], {
        backgroundPositionY: "top",
      });

      gsap.to(e.childNodes[1], {
        duration: 0.4,
        backgroundPositionY: "bottom",
      });
    } else {
      gsap.to(e, {
        duration: 0.3,
        ease: "Circ.easeOut",
        color: "#fff",
      });

      gsap.set(e.childNodes[1], {
        backgroundPositionY: "bottom",
      });

      gsap.to(e.childNodes[1], {
        duration: 0.3,
        backgroundPositionY: "top",
      });
    }
  }

  const options = {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart",
    offset: -150,
    containerId: "main",
  };

  const optionsContact = {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart",
    offset: 0,
    containerId: "main",
  };

  let linkTitle;

  if (i18n?.language == "ja") {
    linkTitle = (
      <>
        <h1
          ref={refTitle}
          onClick={() => {
            window.location.reload();
          }}
          onPointerEnter={() => {
            props.cursor.current.animCursorLogoIn();
          }}
          onPointerLeave={() => {
            props.cursor.current.animCursorLogoOut();
          }}
        >
          SHIFT LINK
        </h1>
      </>
    );
  } else {
    linkTitle = (
      <h1
        ref={refTitle}
        onClick={() => {
          window.location.reload();
        }}
        onPointerEnter={() => {
          props.cursor.current.animCursorLogoIn();
        }}
        onPointerLeave={() => {
          props.cursor.current.animCursorLogoOut();
        }}
      >
        SHIFT LINK
      </h1>
    );
  }

  let linkLang;

  if (i18n?.language == "ja") {
    linkLang = (
      <>
        <Link href="/en" passHref>
          <li
            onPointerEnter={(e) => {
              animLang(e.currentTarget, true);
              props.cursor.current.hideCursor();
            }}
            onPointerLeave={(e) => {
              animLang(e.currentTarget, false);
              props.cursor.current.showCursor();
            }}
            style={{ zIndex: "1" }}
          >
            EN
            <span
              className={`${styles.lang} ${styles.langEn} ${"menu-lang"}`}
            />
          </li>
        </Link>
      </>
    );
  } else {
    linkLang = (
      <Link href="/" locale={false} passHref>
        <li
          onPointerEnter={(e) => {
            animLang(e.currentTarget, true);
            props.cursor.current.hideCursor();
          }}
          onPointerLeave={(e) => {
            animLang(e.currentTarget, false);
            props.cursor.current.showCursor();
          }}
          style={{ zIndex: "1" }}
        >
          JP
          <span className={`${styles.lang} ${styles.langEn} ${"menu-lang"}`} />
        </li>
      </Link>
    );
  }

  return (
    <>
      <div
        className={`${styles.container} ${"containerMenu"}`}
        ref={refContainer}
      >
        {linkTitle}
        <ul ref={refList}>
          <li
            onPointerEnter={(e) => {
              animLine(e.currentTarget.childNodes[1], true);
              props.cursor.current.hideCursor();
            }}
            onPointerLeave={(e) => {
              animLine(e.currentTarget.childNodes[1], false);
              props.cursor.current.showCursor();
            }}
            onClick={() => {
              scroller.scrollTo("services", options);
            }}
          >
            Services
            <span className={`${styles.line} `} />
          </li>
          <li
            onPointerEnter={(e) => {
              animLine(e.currentTarget.childNodes[1], true);
              props.cursor.current.hideCursor();
            }}
            onPointerLeave={(e) => {
              animLine(e.currentTarget.childNodes[1], false);
              props.cursor.current.showCursor();
            }}
            onClick={() => {
              scroller.scrollTo("vision", options);
            }}
          >
            Our Vision
            <span className={`${styles.line} `} />
          </li>

          <li
            onPointerEnter={(e) => {
              animLine(e.currentTarget.childNodes[1], true);
              props.cursor.current.hideCursor();
            }}
            onPointerLeave={(e) => {
              animLine(e.currentTarget.childNodes[1], false);
              props.cursor.current.showCursor();
            }}
            onClick={() => {
              scroller.scrollTo("teams", options);
            }}
          >
            Teams
            <span className={`${styles.line} `} />
          </li>

          <li
            onPointerEnter={(e) => {
              animLine(e.currentTarget.childNodes[1], true);
              props.cursor.current.hideCursor();
            }}
            onPointerLeave={(e) => {
              animLine(e.currentTarget.childNodes[1], false);
              props.cursor.current.showCursor();
            }}
            onClick={() => {
              scroller.scrollTo("contact", optionsContact);
            }}
          >
            Contact
            <span className={`${styles.line} `} />
          </li>
          {linkLang}
        </ul>
      </div>
    </>
  );
});
