import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
  Suspense,
} from "react";

import { gsap } from "gsap";
import { isMobile } from "react-device-detect";

import WobbleImage from "../components/wobbleImage";

export default forwardRef(function MouseFollowerImage(props: any, ref: any) {
  const imageRef = useRef<HTMLDivElement>(null);

  const [imgPath, setImgPath] = useState("");

  useEffect(() => {
    const xSet = gsap.quickSetter(imageRef.current, "x", "px");
    const ySet = gsap.quickSetter(imageRef.current, "y", "px");

    gsap.set(imageRef.current, { xPercent: -50, yPercent: -50 });

    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouse = { x: pos.x, y: pos.y };
    const speed = 0.35;

    const move = (e: MouseEvent) => {
      mouse.x = e.x;
      mouse.y = e.y;
    };

    /*
    window.addEventListener("mousemove", (e) => {
      mouse.x = e.x;
      mouse.y = e.y;
    });
    */

    window.addEventListener("mousemove", move);

    gsap.ticker.add(() => {
      const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;

      xSet(pos.x);
      ySet(pos.y);
    });

    if (imageRef.current != null) {
      if (isMobile) {
        imageRef.current.style.visibility = "hidden";
      }
    }

    return () => {
      window.removeEventListener("mousemove", move);
    };
  }, []);

  useImperativeHandle(ref, () => {
    return {
      animImageCursor(
        path: string,
        isEnter: boolean,
        refMain: any,
        idx: number
      ): void {
        setImgPath(path);

        gsap.killTweensOf(imageRef.current);

        if (isEnter) {
          gsap.set(imageRef.current, { display: "flex", opacity: 0 });

          gsap.to(imageRef.current, {
            duration: 0.5,
            ease: "expo.out",
            opacity: "+=1",
          });
        } else {
          gsap.to(imageRef.current, {
            duration: 0.3,
            opacity: "-=1",
            ease: "expo.out",
            onComplete: () => {
              gsap.set(imageRef.current, { display: "none" });
            },
          });
        }
      },
    };
  });

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        pointerEvents: "none",
        zIndex: 1,
        display: "none",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0,
      }}
      ref={imageRef}
    >
      <WobbleImage
        offset={0}
        path={imgPath}
        size={1}
        canvas={{ x: 250, y: 171 }}
        ratio={{ x: 10, y: 6.84 }}
        mouseFollower={null}
        main={null}
        autoMove={true}
        place="cursor"
        mouseFollowerImage={true}
        sectionSystem={props.sectionSystem}
      />
    </div>
  );
});
