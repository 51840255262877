import React, { useRef, useEffect, forwardRef, useLayoutEffect } from "react";
import styles from "../styles/IconBack.module.css";
import { Element, animateScroll as scroll, scroller } from "react-scroll";

import { gsap } from "gsap";
import DrawSVG from "gsap/dist/DrawSVGPlugin";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { isMobile } from "react-device-detect";

export default (function IconBack(props: any) {
  const refContainer = useRef<HTMLDivElement>(null);

  const refCircle = useRef<SVGPathElement>(null);

  const refArrowToBottom = useRef<HTMLDivElement>(null);
  const refArrowToTop = useRef<HTMLDivElement>(null);

  const refArrowBottom = useRef<SVGGElement>(null);
  const refArrowTop = useRef<SVGGElement>(null);

  gsap.registerPlugin(DrawSVG);

  let isBottom = false;

  const options = {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart",
    containerId: "main",
  };

  useEffect(() => {
    gsap.set(refCircle.current, { drawSVG: "0%" });

    gsap.to(refCircle.current, {
      drawSVG: "100%",
      scrollTrigger: {
        scroller: "#main",
        scrub: 0.3,
      },
    });

    gsap.to(refArrowToBottom.current, {
      id: "toBack",
      scrollTrigger: {
        trigger: props.contact,

        onUpdate: (e) => {
          if (e.progress > 0.999) {
            isBottom = true;
            gsap.killTweensOf(refArrowToTop.current);
            gsap.killTweensOf(refArrowToBottom.current);

            gsap.set(refArrowToBottom.current, { display: "initial" });

            gsap.to(refArrowToTop.current, {
              opacity: 0,
              onComplete: () => {
                gsap.set(refArrowToTop.current, { display: "none" });
              },
            });

            gsap.to(refArrowToBottom.current, {
              opacity: 1,
            });
          } else {
            if (isBottom) {
              gsap.killTweensOf(refArrowToTop.current);
              gsap.killTweensOf(refArrowToBottom.current);

              gsap.set(refArrowToTop.current, { display: "initial" });
              gsap.to(refArrowToTop.current, {
                opacity: 1,
              });

              gsap.to(refArrowToBottom.current, {
                opacity: 0,
                onComplete: () => {
                  gsap.set(refArrowToBottom.current, { display: "none" });
                },
              });
              isBottom = false;
            }
          }
        },

        onLeaveBack: () => {},

        onEnterBack: () => {},

        onLeave: () => {},

        scroller: "#main",
      },
    });

    return () => {
      //ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      //gsap.set(refArrowToBottom.current, { display: "initial", opacity: 1 });
      //gsap.set(refArrowToTop.current, { display: "initial", opacity: 1 });
      //gsap.killTweensOf(refArrowToTop.current);
      //gsap.killTweensOf(refArrowToBottom.current);
      //if (isBottom) {
      //gsap.killTweensOf(refArrowToTop.current);
      //gsap.killTweensOf(refArrowToBottom.current);
      //gsap.set(refArrowToTop.current, { display: "initial", opacity: 1 });
      //gsap.set(refArrowToBottom.current, { display: "none", opacity: 0 });
      //isBottom = false;
      //}
    };
  }, []);

  return (
    <>
      <div
        ref={refContainer}
        className={`${styles.container} ${"back"}`}
        style={{ cursor: "pointer" }}
        onPointerEnter={() => {
          props.cursor.current.hideCursor();

          if (!isMobile) {
            if (
              !gsap.isTweening([refArrowBottom.current, refArrowTop.current])
            ) {
              gsap.killTweensOf([refArrowBottom.current, refArrowTop.current]);

              gsap.fromTo(
                [refArrowBottom.current, refArrowTop.current],
                {
                  translateY: 6,
                },
                {
                  translateY: 26,
                  ease: "sine.inOut",

                  onComplete: () => {
                    gsap.fromTo(
                      [refArrowBottom.current, refArrowTop.current],
                      {
                        translateY: -14,
                      },
                      {
                        translateY: 6,
                        ease: "Power4.inOut",
                      }
                    );
                  },
                }
              );
            }
          }
        }}
        onPointerLeave={() => {
          if (!isMobile) {
            props.cursor.current.showCursor();
          }
        }}
      >
        <div className={styles.arrowContainer}>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
              <path
                transform="translate(1,1)"
                d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z"
                fill="none"
                stroke="#fff"
                strokeWidth="1px"
                ref={refCircle}
              />
            </svg>
          </div>

          <div
            ref={refArrowToTop}
            onClick={() => {
              scroll.scrollMore(innerHeight, options);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <g>
                <path
                  d="M12,0A12,12,0,0,1,24,12,12,12,0,0,1,12,24,12,12,0,0,1,0,12,12,12,0,0,1,12,0Z"
                  fill="#fff"
                />
              </g>

              <g transform="translate(5,6)" ref={refArrowBottom}>
                <path
                  d="M6.682,0v11.871"
                  fill="none"
                  stroke="#1c1c1c"
                  strokeWidth="1"
                />
                <path
                  d="M0,5.149l6.722,6.722,6.722-6.722"
                  fill="none"
                  stroke="#1c1c1c"
                  strokeWidth="1"
                />
              </g>
            </svg>
          </div>

          <div
            ref={refArrowToBottom}
            className={"toBottom"}
            onClick={() => {
              /*
              scroll.scrollToTop({
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
              });
              */

              scroller.scrollTo("top", options);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <g>
                <path
                  d="M12,0A12,12,0,0,1,24,12,12,12,0,0,1,12,24,12,12,0,0,1,0,12,12,12,0,0,1,12,0Z"
                  fill="#fff"
                />
              </g>
              <g transform="translate(5,6)" ref={refArrowTop}>
                <path
                  d="M6.682,0v11.871"
                  fill="none"
                  stroke="#1c1c1c"
                  strokeWidth="1"
                />
                <path
                  d="M0,5.149l6.722,6.722,6.722-6.722"
                  fill="none"
                  stroke="#1c1c1c"
                  strokeWidth="1"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
});
