import React, { useRef, useEffect, Suspense, useMemo, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";

import { useTexture } from "@react-three/drei";
import { MeshBasicMaterial } from "three";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const glsl = require("glslify");

import styles from "../styles/WobbleImage.module.css";

export default function DreiImage(props: any, ref: any) {
  const uniforms = { time: 0 };
  const additive = { time: 0 };

  const refCanvas = useRef<HTMLCanvasElement>(null);
  const [loopState, setLoopState] = useState("never");

  useEffect(() => {
    if (!props.autoMove) {
      gsap.to(uniforms, {
        time: "+=2",

        scrollTrigger: {
          scrub: 3,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!props.mouseFollowerImage) {
      if (refCanvas.current != null) {
        ScrollTrigger.create({
          trigger: refCanvas.current?.parentElement?.parentElement,

          start: "-5% bottom",
          onEnter: () => {
            setLoopState("always");
          },
          onLeave: () => {
            setLoopState("never");
          },
          onLeaveBack: () => {
            setLoopState("never");
          },
          onEnterBack: () => {
            setLoopState("always");
          },
          scroller: "#main",
        });
      }
    }
  }, []);

  useEffect(() => {
    if (props.mouseFollowerImage) {
      if (refCanvas.current != null) {
        ScrollTrigger.create({
          //trigger: "#section-system",
          trigger: props.sectionSystem.current,
          //start: "-10% bottom",
          onEnter: () => {
            setLoopState("always");
          },
          onLeave: () => {
            setLoopState("never");
          },
          onLeaveBack: () => {
            setLoopState("never");
          },
          onEnterBack: () => {
            setLoopState("always");
          },
          scroller: "#main",
        });
      }
    }
  }, []);

  const MaterialImage = () => {
    const size = props.size;

    const texture = useTexture(props.path as string);
    const material = new MeshBasicMaterial();

    material.onBeforeCompile = function (shader) {
      shader.uniforms.uTime = { value: 0 };

      shader.vertexShader =
        [
          glsl`
            uniform float uTime;
            #pragma glslify: snoise2 = require(glsl-noise/simplex/2d)
      `,
        ].join("\n") + shader.vertexShader;

      shader.vertexShader = shader.vertexShader.replace(
        "#include <project_vertex>",
        [
          `
          float n_size = 0.8;
          float n = snoise2(vec2(vUv.x * n_size + uTime, vUv.y * n_size + uTime));
          transformed += vec3(0., 0., n)*0.4;
          vec4 mvPosition = vec4( transformed, 1.0 );

          #ifdef USE_INSTANCING
            mvPosition = instanceMatrix * mvPosition;
          #endif

          mvPosition = modelViewMatrix * mvPosition;
          gl_Position = projectionMatrix * mvPosition;          
        `,
        ].join("\n")
      );

      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <output_fragment>",
        [
          `           
          #ifdef OPAQUE
          diffuseColor.a = 1.0;
          #endif
          // https://github.com/mrdoob/three.js/pull/22425
          #ifdef USE_TRANSMISSION
          diffuseColor.a *= transmissionAlpha + 0.1;
          #endif
          //gl_FragColor = vec4( outgoingLight, diffuseColor.a );
          gl_FragColor = vec4( outgoingLight, 0.86 );
          //gl_FragColor = vec4( outgoingLight, 1. );
        `,
        ].join("\n")
      );

      material.userData.shader = shader;
    };

    material.map = texture;
    let idx = 0;

    useFrame((state) => {
      if (idx % 2 == 0) {
        const shader = material.userData.shader;
        if (shader) {
          if (!props.autoMove) {
            shader.uniforms.uTime.value =
              props.offset + uniforms.time + additive.time;
          } else {
            shader.uniforms.uTime.value = state.clock.elapsedTime * 0.2;
          }
        }
      }
      idx++;
    });

    useEffect(() => {
      return () => {
        //material.dispose();
        //texture.dispose();
      };
    }, []);

    return (
      <mesh
        position={[0, 0, 0]}
        material={material}
        onPointerOver={() => {}}
        onPointerEnter={() => {
          gsap.killTweensOf(additive);
          gsap.to(additive, {
            duration: 3,
            ease: "Power4.easeOut",
            time: "+=0.3",
          });

          props.mouseFollower.current.animCursor("", true, props.main);
        }}
        onPointerLeave={() => {
          props.mouseFollower.current.animCursor("", false, props.main);
        }}
      >
        <planeBufferGeometry
          attach="geometry"
          args={[props.ratio.x * size, props.ratio.y * size, 12, 12]}
        />
      </mesh>
    );
  };

  const DreiImage = useMemo(() => {
    return <MaterialImage></MaterialImage>;
  }, [props.path]);

  return (
    <Canvas
      className={`${props.place == "services" && styles.canvasServices} ${
        props.place == "system" && styles.canvasSystem
      } ${props.place == "cursor" && styles.canvasCursor} ${
        props.place == "systemInfo" && styles.canvasSystemInfo
      }`}
      gl={{}}
      dpr={[2, 2]}
      flat
      linear
      frameloop={
        loopState == "always" /* || props.mouseFollowerImage == true*/
          ? "always"
          : "never"
      }
      ref={refCanvas}
    >
      {/*<Suspense fallback={null}>{DreiImage}</Suspense>*/}
      <Suspense fallback={null}>{DreiImage}</Suspense>
    </Canvas>
  );
}
