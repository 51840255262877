import styles from "../styles/IconContact.module.css";

export default function IconContact(props: any) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="138.599"
        height="89.896"
        viewBox="0 0 138.6 89.9"
        className={styles.icon}
        fill="none"
        stroke="#000"
        strokeWidth="1"
      >
        <g>
          <path d="M0,44.68H137.89" />
          <path d="M93.3,.35l44.59,44.6-44.59,44.59" />
        </g>
      </svg>
    </>
  );
}
