import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import { gsap } from "gsap";
import styles from "../styles/MouseFollower.module.css";

export default forwardRef(function MouseFollower(props: any, ref: any) {
  const ballRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const xSet = gsap.quickSetter(ballRef.current, "x", "px");
    const ySet = gsap.quickSetter(ballRef.current, "y", "px");

    gsap.set(ballRef.current, {
      xPercent: -50,
      yPercent: -50,
    });

    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouse = { x: pos.x, y: pos.y };
    const speed = 0.35;

    window.addEventListener("mousemove", (e) => {
      mouse.x = e.x;
      mouse.y = e.y;

      if (!ballRef.current) return;
      ballRef.current.style.opacity = "1";
    });

    gsap.ticker.add((self) => {
      const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;

      xSet(pos.x);
      ySet(pos.y);
    });
  }, []);

  useImperativeHandle(ref, () => {
    return {
      animCursor(label: string, isEnter: boolean, refMain: any): void {
        if (textRef.current != null) {
          if (label == "Visit\nWebsite") {
            textRef.current.innerHTML = "Visit<br />Website";
          } else {
            textRef.current.innerHTML = label;
          }
        }

        if (isEnter) {
          if (
            label !== "Email Us" &&
            label !== "Visit\nWebsite" &&
            label !== "Play" &&
            label !== "Pause"
          ) {
            gsap.set(ballRef.current, {
              backdropFilter: "invert(1)",
              backgroundColor: "rgba(255,255,255,0)",
              background: "none",
            });
          }

          if (
            label == "Email Us" ||
            label == "Visit\nWebsite" ||
            label == "Play" ||
            label == "Pause"
          ) {
            gsap.set(refMain.current, { cursor: "none" });
            gsap.set(ballRef.current, { width: 15, height: 15 });

            gsap.to(ballRef.current, {
              duration: 0.3,
              ease: "expo.out",
              width: "140px",
              height: "140px",
            });
          }
        } else {
          gsap.set(refMain.current, { cursor: "default" });

          if (textRef.current != null) {
            textRef.current.innerHTML = "";
          }

          gsap.set(ballRef.current, {
            backdropFilter: "invert(0)",
            backgroundColor: "rgba(255,255,255,255)",
          });

          if (
            label == "Email Us" ||
            label == "Visit\nWebsite" ||
            label == "Play" ||
            label == "Pause"
          ) {
            gsap.set(ballRef.current, { width: 115, height: 115 });
            gsap.to(ballRef.current, {
              duration: 0.3,
              ease: "expo.out",
              width: "20px",
              height: "20px",
            });
          }
        }
      },

      changeLabel(label: string): void {
        if (textRef.current != null) {
          textRef.current.innerHTML = label;
        }
      },
      hideCursor(): void {
        gsap.to(ballRef.current, {
          duration: 0.3,
          ease: "expo.out",
          width: "5px",
          height: "5px",
        });
      },
      showCursor(): void {
        gsap.to(ballRef.current, {
          duration: 0.3,
          ease: "expo.out",
          width: "20px",
          height: "20px",
        });
      },

      animCursorLogoIn(): void {
        gsap.to(ballRef.current, {
          duration: 0.3,
          ease: "expo.out",
          width: "22px",
          height: "22px",
          border: "solid",
          borderWidth: "1px",
          color: "#fff",
          backgroundColor: "rgba(255,255,255,0)",
        });
      },

      animCursorLogoOut(): void {
        gsap.to(ballRef.current, {
          duration: 0.3,
          ease: "expo.out",
          width: "20px",
          height: "20px",
          color: "rgba(0,0,0,1)",
          border: "none",
          backgroundColor: "rgba(255,255,255,1)",
        });
      },
    };
  });

  return (
    <div className={`${styles.mouseFollower} ${"cursor"}`} ref={ballRef}>
      <span
        ref={textRef}
        style={{
          fontSize: "12px",
        }}
      ></span>
    </div>
  );
});
