import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  useLayoutEffect,
  forwardRef,
  Suspense,
} from "react";

import {
  Group,
  Vector3,
  Scene,
  OrthographicCamera,
  Euler,
  NearestFilter,
  TextureDataType,
  HalfFloatType,
  FloatType,
  RGBAFormat,
  MeshLambertMaterial,
  Color,
  Vector2,
  LinearFilter,
  WebGLRenderTarget,
  SphereBufferGeometry,
} from "three";

import {
  ShaderPass,
  SavePass,
  EffectComposer,
  RenderPass,
  UnrealBloomPass,
  CopyShader,
  BlendShader,
} from "three-stdlib";

import { Canvas, createPortal, useFrame, useThree } from "@react-three/fiber";
import { useFBO, PerspectiveCamera } from "@react-three/drei";

import { isMobile } from "react-device-detect";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useMediaQuery } from "react-responsive";

import "../components/passthroughMaterial";
import "../components/simulationMaterial";

export default forwardRef(function Particles<Ref>(props: any, ref: any) {
  const _speed = 1.4;
  const _noiseFreq = 2;
  const _noiseSize = 0.1;
  const _noiseSpeed = 2.5;
  const _noiseAdd = 0;
  const _colorA = { r: 53, g: 53, b: 199 };
  const _colorB = { r: 16, g: 98, b: 109 };
  const _hueMix = 3;
  const _roughness = 0.4;
  const _size = 0.9;
  const _calc = true;

  const _rot = useMemo(() => {
    const _rot = { x: 0, y: 0, z: 0 };
    return _rot;
  }, []);

  const _color = useMemo(() => {
    const _color = {
      col1r: 53,
      col1g: 53,
      col1b: 199,
      col2r: 16,
      col2g: 98,
      col2b: 109,
    };
    return _color;
  }, []);

  const groupRef = useRef<Group>();
  const meshRef = useRef<JSX.IntrinsicElements["mesh"]>();

  //if (gsap.getById("rot") != null) {
  //gsap.getById("rot").kill();
  //}

  useEffect(() => {
    gsap.to(_rot, {
      id: "rot",
      x: "-=3.5",

      scrollTrigger: {
        id: "scrollRot",
        scrub: 4,
        scroller: "#main",
      },
    });
  }, [_rot]);

  useEffect(() => {
    gsap.to(_color, {
      col1r: 50,
      col1g: 142,
      col1b: 230,
      col2r: 247,
      col2g: 148,
      col2b: 20,

      scrollTrigger: {
        id: "scrollCol",
        scrub: 0.3,
        scroller: "#main",
      },
    });
  }, [_color]);

  function Particle(props: any, ref: any) {
    const size = 156;

    const simRef = useRef<JSX.IntrinsicElements["passthroughMaterial"]>(null);
    const simSecondRef =
      useRef<JSX.IntrinsicElements["simulationMaterial"]>(null);

    const scene = useMemo(() => {
      const scene = new Scene();
      return scene;
    }, []);

    const scene_ = useMemo(() => {
      const scene = new Scene();
      return scene;
    }, []);

    const camera = useMemo(() => {
      const camera = new OrthographicCamera(
        -1,
        1,
        1,
        -1,
        1 / Math.pow(2, 53),
        1
      );
      return camera;
    }, []);

    const positions = useMemo(() => {
      const positions = new Float32Array([
        -1, -1, 0, 1, -1, 0, 1, 1, 0, -1, -1, 0, 1, 1, 0, -1, 1, 0,
      ]);
      return positions;
    }, []);

    const uvs = useMemo(() => {
      const uvs = new Float32Array([0, 1, 1, 1, 1, 0, 0, 1, 1, 0, 0, 0]);
      return uvs;
    }, []);

    const pos = new Vector3();
    const rot = new Euler();
    const scale = new Vector3(1.5, 1, 1);
    if (props.isFlip) {
      rot.set(Math.PI / 1, 0, 0);
    } else {
      rot.set(Math.PI / 2, 0, Math.PI / -1);
    }

    let dataType: TextureDataType;

    const { gl } = useThree();

    if (gl.capabilities.isWebGL2) {
      dataType = FloatType;
    } else {
      dataType = HalfFloatType;
    }

    const target = useFBO(size, size, {
      minFilter: NearestFilter,
      magFilter: NearestFilter,
      format: RGBAFormat,
      type: dataType,
    });

    const targetSecond = useFBO(size, size, {
      minFilter: NearestFilter,
      magFilter: NearestFilter,
      format: RGBAFormat,
      type: dataType,
    });

    const references = useMemo(() => {
      const length = size * size * 2;
      const references = new Float32Array(length * 2);

      for (let i = 0; i < length; i++) {
        references[i * 2] = (i % size) / size;
        references[i * 2 + 1] = ~~(i / size) / size;
      }
      return references;
    }, []);

    const baseGeo = useMemo(() => {
      const baseGeo = new SphereBufferGeometry(0.004, 1, 1);
      return baseGeo;
    }, []);

    const material = useMemo(() => {
      const material = new MeshLambertMaterial();
      material.onBeforeCompile = function (shader) {
        shader.uniforms.particleSize = { value: props.particleSize };
        shader.uniforms.hueMix = { value: props.hueMix };
        shader.uniforms.noiseSize = { value: 0 };

        shader.vertexShader =
          [
            `
                attribute vec2 reference;
                varying float id;
                uniform sampler2D positions;
                uniform float particleSize;
                `,
          ].join("\n") + shader.vertexShader;

        shader.vertexShader = shader.vertexShader.replace(
          "#include <project_vertex>",
          [
            `
                  vec4 noise_pos = texture2D(positions, reference);
                  vec3 vPosition = noise_pos.xyz + (transformed * particleSize);
                  vec4 mvPosition = modelViewMatrix * vec4( vPosition, 1.0 );

                  gl_Position = projectionMatrix * modelViewMatrix * vec4(vPosition, 1.0 );
                  id = noise_pos.w;
                `,
          ].join("\n")
        );

        shader.fragmentShader =
          [
            `
                varying float id;
                uniform float hueMix;
    
                vec3 Hue(vec3 color, float hue) {
                  const vec3 k = vec3(0.57735, 0.57735, 0.57735);
                  float cosAngle = cos(hue);
                  return vec3(color * cosAngle + cross(k, color) * sin(hue) + k * dot(k, color) * (1.0 - cosAngle));
                }
              `,
          ].join("\n") + shader.fragmentShader;

        shader.fragmentShader = shader.fragmentShader.replace(
          "#include <color_fragment>",
          [
            `
                #if defined( USE_COLOR_ALPHA )
                  diffuseColor *= vColor;
                #elif defined( USE_COLOR )
                  diffuseColor.rgb *= vColor;
                #endif
                
                vec3 c = Hue(diffuseColor.rgb, id*hueMix);    
                diffuseColor = vec4(c,1.);
                `,
          ].join("\n")
        );

        material.userData.shader = shader;
      };

      return material;
    }, [props]);

    material.color = new Color(
      props.particleColor.r / 255,
      props.particleColor.g / 255,
      props.particleColor.b / 255
    );

    material.fog = false;

    useFrame((state, delta) => {
      const shader = material.userData.shader;
      if (shader) {
        shader.uniforms.noiseSize.value = 0;

        if (!props.isFlip) {
          material.color.r = _color.col1r / 255;
          material.color.g = _color.col1g / 255;
          material.color.b = _color.col1b / 255;
        } else {
          material.color.r = _color.col2r / 255;
          material.color.g = _color.col2g / 255;
          material.color.b = _color.col2b / 255;
        }
      }

      state.gl.setRenderTarget(target);
      state.gl.render(scene, camera);

      if (simRef.current != null) {
        simRef.current.setPrevPositions = targetSecond.texture;
      }

      state.gl.setRenderTarget(targetSecond);
      state.gl.render(scene_, camera);

      if (simSecondRef.current != null) {
        const offset = delta / (1 / 60);

        simSecondRef.current.setUTime = state.clock.elapsedTime * 0.1;
        simSecondRef.current.setPositions = target.texture;
        simSecondRef.current.setSpeed = 1.4 * offset;
        simSecondRef.current.setNoiseFreq = props.noiseFreq;
        simSecondRef.current.setNoiseSize = props.noiseSize * 0.01;
        simSecondRef.current.setNoiseSpeed = props.noiseSpeed;
        simSecondRef.current.setNoiseAdd = props.noiseAdd;

        simSecondRef.current.setStop = props.calculation;
      }
    });

    useEffect(() => {
      return () => {
        target.dispose();
        targetSecond.dispose();
        material.dispose();
      };
    }, []);

    return (
      <>
        {createPortal(
          <>
            <mesh>
              <passthroughMaterial ref={simRef} />
              <bufferGeometry>
                <bufferAttribute
                  attachObject={["attributes", "position"]}
                  count={positions.length / 3}
                  array={positions}
                  itemSize={3}
                />
                <bufferAttribute
                  attachObject={["attributes", "uv"]}
                  count={uvs.length / 2}
                  array={uvs}
                  itemSize={2}
                />
              </bufferGeometry>
            </mesh>
          </>,
          scene
        )}

        {createPortal(
          <>
            <mesh>
              <simulationMaterial ref={simSecondRef} />
              <bufferGeometry>
                <bufferAttribute
                  attachObject={["attributes", "position"]}
                  count={positions.length / 3}
                  array={positions}
                  itemSize={3}
                />
                <bufferAttribute
                  attachObject={["attributes", "uv"]}
                  count={uvs.length / 2}
                  array={uvs}
                  itemSize={2}
                />
              </bufferGeometry>
            </mesh>
          </>,
          scene_
        )}

        <mesh
          material={material}
          rotation={rot}
          position={pos}
          scale={scale}
          ref={meshRef}
        >
          <instancedBufferGeometry
            index={baseGeo.index}
            attributes-position={baseGeo.attributes.position}
            attributes-uv={baseGeo.attributes.uv}
            attributes-normal={baseGeo.attributes.normal}
            attach="geometry"
          >
            <instancedBufferAttribute
              attachObject={["attributes", "reference"]}
              args={[references, 2]}
            />
          </instancedBufferGeometry>
        </mesh>
      </>
    );
  }

  //const Effects = useMemo(() => {
  const Effects = () => {
    const {
      gl,
      scene: defaultScene,
      camera: defaultCamera,
      viewport,
    } = useThree();

    const composer = useMemo(() => {
      const composer = new EffectComposer(gl);
      const renderPass = new RenderPass(defaultScene, defaultCamera);

      composer.addPass(renderPass);
      return composer;
    }, [gl, defaultScene, defaultCamera]);

    const bloomPass = useMemo(() => {
      let width = window.innerWidth;
      let height = window.innerHeight;

      const bloomPass = new UnrealBloomPass(
        new Vector2(width, height),
        1.5,
        0.4,
        0.85
      );

      return bloomPass;
    }, []);

    useLayoutEffect(() => {
      bloomPass.strength = 0.6;
      bloomPass.threshold = 0.2;
      bloomPass.radius = 0.05;

      composer.addPass(bloomPass);

      return () => {
        composer.removePass(bloomPass);
      };
    }, [composer, bloomPass]);

    let width: number;
    let height: number;

    const renderTarget = useMemo(() => {
      width = window.innerWidth;
      height = window.innerHeight;

      const renderTargetParameters = {
        minFilter: LinearFilter,
        magFilter: LinearFilter,
      };

      const renderTarget = new WebGLRenderTarget(
        width,
        height,
        renderTargetParameters
      );

      return renderTarget;
    }, [window.innerWidth, window.innerHeight]);

    useLayoutEffect(() => {
      const blendPass = new ShaderPass(BlendShader, "tDiffuse1");
      const savePass = new SavePass(renderTarget);
      const outputPass = new ShaderPass(CopyShader);

      blendPass.uniforms["tDiffuse2"].value = savePass.renderTarget.texture;
      blendPass.uniforms["mixRatio"].value = 0.7;

      composer.addPass(blendPass);
      composer.addPass(savePass);
      composer.addPass(outputPass);

      return () => {
        composer.removePass(blendPass);
        composer.removePass(savePass);
        composer.removePass(outputPass);
      };
    }, [composer, renderTarget]);

    let idxFrame = 0;
    let x = 0;

    useFrame((_, delta) => {
      gl.autoClear = true;
      composer.render(delta);

      x -= 0.004;

      if (groupRef.current != null) {
        groupRef.current.rotation.x = _rot.x + x;
      }
    }, 1);

    /*
      const resize = () => {
        
        if (!isMobile) {
          bloomPass.setSize(window.innerWidth, window.innerHeight);
          composer.reset(renderTarget);

          renderTarget.setSize(window.innerWidth, window.innerHeight);

          composer.renderer.setPixelRatio(viewport.dpr);
          composer.renderer.setSize(window.innerWidth, window.innerHeight);
        }
      };
      */

    useEffect(() => {
      //window.addEventListener("resize", resize);

      return () => {
        //window.removeEventListener("resize", resize);
        //renderTarget.dispose();
        //gl.dispose();
      };
    }, [composer]);

    return null;
  };

  //return Effects;
  //}, []);

  const [resizeCount, setResizeCount] = useState(0);

  const handleMediaQueryChange = (matches: boolean) => {
    setResizeCount(resizeCount + 1);
  };

  useMediaQuery({ orientation: "portrait" }, undefined, handleMediaQueryChange);

  useEffect(() => {
    const resize = () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.refresh());

      setResizeCount(resizeCount + 1);

      /*
      gsap.to(_rot, {
        id: "rot",
        x: "-=3.5",

        scrollTrigger: {
          id: "scrollRot",
          scrub: 4,
          scroller: "#main",
        },
      });

      gsap.to(_color, {
        col1r: 50,
        col1g: 142,
        col1b: 230,
        col2r: 247,
        col2g: 148,
        col2b: 20,

        scrollTrigger: {
          id: "scrollCol",
          scrub: 0.3,
          scroller: "#main",
        },
      });
      */
      if (ScrollTrigger.getById("scrollRot")) {
        //ScrollTrigger.getById("scrollRot").kill();
      }

      if (ScrollTrigger.getById("scrollCol")) {
        //ScrollTrigger.getById("scrollCol").kill();
      }
    };

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  });

  return (
    <>
      <Canvas
        dpr={0.75}
        gl={{
          antialias: false,
          alpha: true,
        }}
        style={{
          position: "fixed",
          width: "100%",
          height: "100vh",
          zIndex: 1,
          pointerEvents: "none",
        }}
        key={resizeCount}
      >
        <Suspense fallback={null}>
          <PerspectiveCamera makeDefault position={[0, 0, 1]} />
          <pointLight position={[1, 10, 0]} color={[1, 1, 1]} intensity={1.8} />

          <group ref={groupRef}>
            <Particle
              particleSize={_size}
              particleColor={_colorA}
              hueMix={_hueMix}
              roughness={_roughness}
              speed={_speed}
              noiseFreq={_noiseFreq}
              noiseSize={_noiseSize}
              noiseSpeed={_noiseSpeed}
              noiseAdd={_noiseAdd}
              calculation={_calc}
              vertNoise={0}
            />

            <Particle
              particleSize={_size}
              particleColor={_colorB}
              hueMix={_hueMix}
              roughness={_roughness}
              speed={_speed}
              noiseFreq={_noiseFreq}
              noiseSize={_noiseSize}
              noiseSpeed={_noiseSpeed}
              noiseAdd={_noiseAdd}
              calculation={_calc}
              isFlip={true}
              vertNoise={0}
            />
          </group>

          <Effects />
        </Suspense>
      </Canvas>
    </>
  );
});
