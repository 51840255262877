import styles from "../styles/IconTagArrow.module.css";

export default function IconBack(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6.08"
      height="10.49"
      viewBox="0 0 6.08 10.49"
      className={styles.arrow}
    >
      <path
        d="M.35,.36L5.37,5.33,.35,10.13"
        fill="none"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinejoin="miter"
      />
    </svg>
  );
}
