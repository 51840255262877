import styles from "../styles/IconLogo.module.css";

export default function IconLogo(props: any) {
  return (
    <>
      <svg
        fill="#fff"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 197.9 24.61"
        className={styles.icon}
      >
        <path d="M0,7.21C2.02,1.56,8.24-1.38,13.89,.64c5.57,1.99,8.53,8.08,6.64,13.69l-4.24-1.47c1.14-3.31-.6-6.93-3.9-8.1h-.04c-3.33-1.17-6.96,.59-8.11,3.92h0L0,7.21Z" />
        <path d="M8.09,10.13c-1.99,5.66,.98,11.87,6.64,13.86,5.61,1.97,11.76-.92,13.81-6.5l-4.23-1.52c-1.19,3.3-4.82,5.02-8.12,3.85h-.04c-3.31-1.21-5.03-4.86-3.84-8.17l-4.23-1.52Z" />
        <path d="M58.8,16.56c0,3.69-2.52,6.45-7.86,6.45s-9.27-2.82-9.48-7.65h3.42c.24,2.88,1.95,4.83,5.97,4.83,3.42,0,4.5-1.5,4.5-3.45,0-2.73-2.1-3.12-6-4.11-3.36-.87-7.05-1.98-7.05-6.06,0-3.72,2.88-5.85,7.5-5.85,4.89,0,8.07,2.49,8.4,6.81h-3.45c-.33-2.58-1.8-3.99-4.95-3.99-2.67,0-4.08,.99-4.08,2.85,0,2.4,2.28,2.91,5.04,3.6,4.14,1.05,8.04,2.04,8.04,6.57Z" />
        <path d="M64.9,1.14V10.11h10.53V1.14h3.33V22.59h-3.33V12.96h-10.53v9.63h-3.3V1.14h3.3Z" />
        <path d="M85.9,1.14V22.59h-3.3V1.14h3.3Z" />
        <path d="M102.39,10.44v2.85h-9.36v9.3h-3.3V1.14h14.88V3.99h-11.58v6.45h9.36Z" />
        <path d="M112.95,3.99h-6.93V1.14h17.19V3.99h-6.93V22.59h-3.33V3.99Z" />
        <path d="M138.56,1.14V19.74h10.86v2.85h-14.16V1.14h3.3Z" />
        <path d="M155.24,1.14V22.59h-3.3V1.14h3.3Z" />
        <path d="M159.08,1.14h3.78l9.96,16.5h.06V1.14h3.33V22.59h-3.78l-9.99-16.5h-.06V22.59h-3.3V1.14Z" />
        <path d="M180.05,1.14h3.3V12l10.17-10.86h3.69l-8.4,8.94,9.09,12.51h-3.87l-7.26-10.32-3.42,3.63v6.69h-3.3V1.14Z" />
      </svg>
    </>
  );
}
