import React, { useEffect, useRef } from "react";
import styles from "../styles/MenuMobile.module.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { Element, animateScroll as scroll, scroller } from "react-scroll";
import { i18n } from "next-i18next";
import Link from "next/link";
import { isMobile } from "react-device-detect";

let isInvert = false;

export default (function MenuMobile(props: any, ref: any) {
  const refHead = useRef<HTMLHeadingElement>(null);
  const refHeadContainer = useRef<HTMLDivElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const refPanel = useRef<HTMLDivElement>(null);

  const refMenuLabel = useRef<HTMLSpanElement>(null);

  const refMenuButton = useRef<HTMLSpanElement>(null);
  const refList = useRef<HTMLUListElement>(null);

  const refBlack = useRef<HTMLDivElement>(null);

  const refFoot = useRef<HTMLUListElement>(null);
  const refCopyright = useRef<HTMLDivElement>(null);

  const refInnerContainer = useRef<HTMLDivElement>(null);

  const delayOpen = 0.4;
  const delayClose = 0.0;
  const delayPanelClose = 0.2;

  const durationListIn = 0.8;
  const durationListOut = 0.2;

  const delayList = 0.4;

  const durationOpen = 0.2;
  const durationClose = 0.2;

  const durationHead = 0.5;

  const durationBlackIn = 0.3;
  const durationBlackOut = 0.2;

  const ease = "";

  let isOpen = false;

  const options = {
    duration: 1000,
    delay: 0,
    smooth: "easeInOutQuart",
    offset: -150,
    containerId: "main",
  };

  const options2 = {
    duration: 1000,
    delay: 0,
    smooth: "easeInOutQuart",
    offset: 0,
    containerId: "main",
  };

  useEffect(() => {
    gsap.to(refHeadContainer.current, {
      filter: "invert(1)",

      scrollTrigger: {
        trigger: props.contact.current,
        scrub: 0.1,
        end: "top",
        onEnter: () => {
          isInvert = true;
        },
        onLeaveBack: () => {
          isInvert = false;
        },
        scroller: "#main",
      },
    });

    refList.current?.childNodes.forEach((e) => {
      animTitleReset();
    });

    if (refContainer.current != null) {
      if (isMobile) {
        refContainer.current.style.visibility = "initial";
      }
    }

    function updateSize() {
      if (!isMobile) {
        closeMenu();
      }
    }

    window.addEventListener("resize", updateSize);

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  function animTitle(ref: any): void {
    let deley = delayList;
    refList.current?.childNodes.forEach((e: any) => {
      gsap.to(e.childNodes[0], {
        duration: durationListIn,
        opacity: 1,
        y: 0,
        delay: deley,
        rotateX: 0,
        rotateZ: 0,
        ease: "Circ.easeOut",
        stagger: 0.3,
      });

      deley += 0.1;
    });
  }

  function animTitleReverse() {
    let _deley = 0;

    refList.current?.childNodes.forEach((e: any) => {
      gsap.to(e.firstChild, {
        y: 50,
        //rotateX: -100,
        //rotateZ: 15,
        opacity: 0,
        duration: durationListOut,
        stagger: 0.3,
        delay: _deley,
      });
    });
  }

  function animTitleReset() {
    refList.current?.childNodes.forEach((e: any) => {
      gsap.killTweensOf(e.childNodes[0]);

      gsap.set(e.childNodes[0], {
        overflow: "hidden",
        y: 100,
        rotateX: -100,
        rotateZ: 15,

        opacity: 0,
        transformOrigin: "50% 50%",
      });
    });
  }

  function toggleMenu() {
    gsap.killTweensOf([
      refContainer.current,
      refPanel.current,
      refList.current,
      refBlack.current,
      refHead.current,
    ]);

    gsap.set(refPanel.current, { visibility: "initial" });
    gsap.set(refFoot.current, { pointerEvents: "all" });

    if (!isOpen) {
      gsap.to(refBlack.current, {
        opacity: 1,
        duration: durationBlackIn,
      });

      animTitleReset();
      animTitle(refList);

      gsap.to(refContainer.current, {
        backgroundColor: "rgba(234, 234, 234, 1)",
        duration: durationOpen,
        ease: "Circ.easeOut",
        delay: delayOpen,
      });

      gsap.to([refPanel.current, refInnerContainer.current], {
        opacity: 1,
        duration: durationOpen,
        delay: delayOpen,
        ease: "Circ.easeOut",
      });

      if (refHead.current != null) {
        gsap.to(refHeadContainer.current, {
          filter: "invert(0)",
        });

        gsap.to(refHead.current.childNodes[0], {
          y: 50,
          duration: durationHead,
          ease: "Circ.easeInOut",

          onComplete: () => {
            if (refHead.current != null) {
              gsap.set(refHead.current.childNodes[0], {
                color: "#000",
                y: 50,
              });

              gsap.to(refHead.current.childNodes[0], {
                y: 0,
                duration: durationHead,
                //ease: "Circ.easeOut",
                ease: "Circ.easeInOut",

                delay: delayOpen,
              });
            }
          },
        });
      }

      if (refCopyright.current != null) {
        gsap.set(refCopyright.current.childNodes[0], {
          y: 0,
        });

        gsap.from(refCopyright.current.childNodes[0], {
          y: 50,
          delay: delayOpen,
        });
      }

      gsap.to(refMenuButton.current, {
        backgroundPositionY: "bottom",
      });

      gsap.set(refMenuButton.current, {
        duration: 1,
        borderWidth: "0",
        borderColor: "rgba(0,0,0,255)",
      });

      isOpen = true;
      gsap.set("main", { pointerEvents: "none" });

      if (refMenuLabel.current != null) {
        refMenuLabel.current.innerText = " Close";
      }
    } else {
      closeMenu();
    }
  }

  function closeMenu() {
    animTitleReverse();

    gsap.set(refFoot.current, { pointerEvents: "none" });

    gsap.to(refBlack.current, {
      opacity: 0,
      duration: durationBlackOut,
      delay: delayClose,
    });

    gsap.to(refContainer.current, {
      backgroundColor: "rgba(234, 234, 234, 0)",
      duration: durationClose,
      ease: "Circ.easeOut",
      delay: delayPanelClose,
    });

    gsap.to([refPanel.current, refInnerContainer.current], {
      opacity: 0,
      duration: durationClose,
      ease: "Circ.easeOut",
      delay: delayPanelClose,
      onComplete: () => {
        gsap.set(refPanel.current, { visibility: "hidden" });
      },
    });

    if (refHead.current != null) {
      if (isInvert) {
        gsap.set(refHeadContainer.current, {
          filter: "invert(1)",
        });
      }

      gsap.to(refHead.current.childNodes[0], {
        y: 50,
        duration: durationHead,
        ease: "Power2.easeInOut",

        onComplete: () => {
          if (refHead.current != null) {
            gsap.set(refHead.current.childNodes[0], {
              color: "#fff",
              y: 50,
            });

            gsap.to(refHead.current.childNodes[0], {
              y: 0,
              duration: durationClose + 0.3,
              ease: "Circ.easeInOut",
              delay: delayOpen,
            });
          }
        },
      });
    }

    if (refCopyright.current != null) {
      gsap.to(refCopyright.current.childNodes[0], {
        y: 50,
        delay: 0,
      });
    }

    gsap.to(refMenuButton.current, {
      backgroundPositionY: "top",
      borderColor: "rgba(255,255,255,1)",
      borderWidth: "1px",
    });

    if (refMenuLabel.current != null) {
      refMenuLabel.current.innerText = " Menu";
    }

    isOpen = false;
    gsap.set("main", { pointerEvents: "all" });
  }

  /*
  let lang;

  if (i18n?.language == "ja") {
    lang = (
      <>
        <Link href="/en" passHref>
          EN
        </Link>
      </>
    );
  } else {
    lang = (
      <>
        <Link href="/" locale={false} passHref>
          JP
        </Link>
      </>
    );
  }
  */

  useEffect(() => {
    ScrollTrigger.getAll().forEach((trigger) => trigger.refresh());
  }, []);

  return (
    <>
      <div
        ref={refBlack}
        style={{
          top: 0,
          opacity: 0,
          position: "fixed",
          background: "#000",
          width: "100vw",
          height: "100vh",
          zIndex: 10,
          pointerEvents: "none",
        }}
      ></div>

      <div className={styles.container} ref={refContainer}>
        <div className={`${styles.head}`} ref={refHeadContainer}>
          <h1
            ref={refHead}
            onPointerEnter={() => {
              props.cursor.current.animCursorLogoIn();
            }}
            onPointerLeave={() => {
              props.cursor.current.animCursorLogoOut();
            }}
            style={{ pointerEvents: "all" }}
            onClick={() => {
              window.location.reload();
            }}
          >
            <div>SHIFT LINK</div>
          </h1>
          <div
            onClick={() => {
              toggleMenu();
            }}
          >
            <span ref={refMenuLabel}>Menu</span> <span ref={refMenuButton} />
          </div>
        </div>

        <div className={styles.innerContainer} ref={refInnerContainer}>
          <div className={styles.panel} ref={refPanel}>
            <ul ref={refList}>
              <li
                onClick={() => {
                  scroller.scrollTo("services", options);
                  closeMenu();
                }}
              >
                <div>Services</div>
              </li>
              <li
                onClick={() => {
                  scroller.scrollTo("vision", options);
                  closeMenu();
                }}
              >
                <div>Our Vision</div>
              </li>
              <li
                onClick={() => {
                  scroller.scrollTo("teams", options);
                  closeMenu();
                }}
              >
                <div>Teams</div>
              </li>
              <li
                onClick={() => {
                  scroller.scrollTo("contact", options2);
                  closeMenu();
                }}
              >
                <div>Contact</div>
              </li>
            </ul>

            <div className={styles.copyrights} ref={refCopyright}>
              <div>© 2022 The Shift, Inc. All rights reserved.</div>
            </div>
          </div>

          <div className={styles.foot}>
            {/*
            <ul ref={refFoot}>
              <li>
                {lang}
                <span />
              </li>
            </ul>
              */}

            {i18n?.language == "ja" && (
              <ul ref={refFoot}>
                <Link href="/en" passHref>
                  <li style={{ zIndex: 1 }}>
                    EN
                    <span />
                  </li>
                </Link>
              </ul>
            )}

            {i18n?.language == "en" && (
              <ul ref={refFoot}>
                <Link href="/" locale={false} passHref>
                  <li style={{ zIndex: 1 }}>
                    JP
                    <span />
                  </li>
                </Link>
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
});
