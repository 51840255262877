import React, { useRef, forwardRef, useImperativeHandle } from "react";
import styles from "../styles/IconPlus.module.css";

import { gsap } from "gsap";

export default forwardRef(function IconPlus(props: any, ref: any) {
  const refContainer = useRef<HTMLDivElement>(null);
  const refIcon = useRef<SVGSVGElement>(null);
  const refIconMinus = useRef<SVGSVGElement>(null);

  useImperativeHandle(ref, () => {
    return {
      togglePlus(togglePlus: boolean): void {
        gsap.killTweensOf(refIcon.current);
        gsap.killTweensOf(refIconMinus.current);

        if (togglePlus) {
          gsap.to(refIcon.current, {
            opacity: 1,
            duration: 0.5,
            rotate: "0",
          });

          gsap.to(refIconMinus.current, {
            opacity: 1,
            duration: 0.5,
            rotate: "0",
          });
        } else {
          // on

          gsap.to(refIcon.current, {
            opacity: 0,
            duration: 0.5,
            rotate: "90",
          });

          gsap.to(refIconMinus.current, {
            opacity: 1,
            duration: 0.5,
            rotate: "90",
          });
        }
      },
    };
  });

  return (
    <>
      <div
        ref={refContainer}
        className={styles.container}
        onPointerMove={(e) => {
          props.cursor.current.hideCursor();

          if (refContainer.current != null) {
            const { left, top, width, height } =
              refContainer.current.getBoundingClientRect();
            const halfW = width / 2;
            const halfH = height / 2;
            const mouseX = e.clientX - left;
            const mouseY = e.clientY - top;

            const x = gsap.utils.interpolate(-halfW, halfW, mouseX / width);
            const y = gsap.utils.interpolate(-halfH, halfH, mouseY / height);

            gsap.to(refIcon.current, {
              x: x,
              y: y,
              duration: 0.5,
              ease: "power3",
            });

            gsap.to(refIconMinus.current, {
              x: x,
              y: y,
              duration: 0.5,
              ease: "power3",
            });
          }
        }}
        onPointerLeave={() => {
          props.cursor.current.showCursor();

          gsap.to(refIcon.current, {
            x: 0,
            y: 0,
            ease: "power3",
            duration: 0.5,
          });

          gsap.to(refIconMinus.current, {
            x: 0,
            y: 0,
            ease: "power3",
            duration: 0.5,
          });
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="122"
          height="122"
          viewBox="0 0 122 122"
          className={styles.icon}
          ref={refIcon}
        >
          <line
            x2="122"
            transform="translate(0 61)"
            fill="none"
            stroke="#fff"
            strokeWidth="3"
          />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="122"
          height="122"
          viewBox="0 0 122 122"
          className={styles.icon}
          ref={refIconMinus}
          style={{ opacity: 1 }}
        >
          <line
            x2="122"
            transform="translate(61 0) rotate(90)"
            fill="none"
            stroke="#fff"
            strokeWidth="3"
          />
        </svg>
      </div>
    </>
  );
});
