import React, { useRef, useMemo, useEffect, useState } from "react";
import dynamic from "next/dynamic";

import type { NextPage } from "next";
import Link from "next/link";
import Image from "next/image";
import { i18n } from "next-i18next";
import { useTranslation } from "next-i18next";

import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { isMobile, isIOS, isAndroid } from "react-device-detect";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { SplitText } from "gsap/dist/SplitText";
import { useMediaQuery } from "react-responsive";

//import Collapsible from "react-collapsible";

const Collapsible = dynamic(
  () => {
    return import("react-collapsible");
  },
  { ssr: false }
);

import { Element, animateScroll as scroll, scroller } from "react-scroll";

const MediaQuery = dynamic(
  () => {
    return import("react-responsive");
  },
  { ssr: false }
);

import Particles from "../components/particles";
import Menu from "../components/menu";
import MenuMobile from "../components/menuMobile";

import IconPlus from "../components/iconPlus";
import IconBack from "../components/iconBack";
import IconContact from "../components/iconContact";
import IconTagArrow from "../components/iconTagArrow";
import IconLogo from "../components/iconLogo";
import LogoAircord from "../components/logoAircord";

import WobbleImage from "../components/wobbleImage";
import ReactPlayer from "react-player/lazy";
import MouseFollower from "../components/mouseFollower";
import MouseFollowerImage from "../components/mouseFollowerImage";

import styles from "../styles/Top.module.css";
import stylesJa from "../styles/TopJa.module.css";

import Head from "next/head";

interface Handler {
  animCursor(label: string, isEnter: boolean, ref: any): void;
  showCursor(): void;
  hideCursor(): void;
  animCursorIn(): void;
  animImageCursor(path: string, isEnter: boolean, ref: any, idx: number): void;
  changeLabel(label: string): void;
  showMenu(): void;
  initMenu(delay: number): void;
}

interface IconHander {
  togglePlus(isSet: boolean): void;
}

const Top: NextPage = () => {
  const tlContact = gsap.timeline();
  tlContact.pause();
  tlContact.repeatDelay(1.2);
  tlContact.delay(4);

  const _isMobile = useMediaQuery({ query: "(max-width: 1280px)" });
  const __isMobile = useMediaQuery({ query: "(max-width: 640px)" });

  const particlesRef = useRef({
    focusHandler: () => {},
  });

  const { t } = useTranslation("common");

  let topIdx = 0;

  const topWords = [t("connect-1"), t("connect-2"), t("connect-3")];

  const [isPlay, setPlay] = useState(false);
  const [isPlayB, setPlayB] = useState(false);
  const [isPlayC, setPlayC] = useState(false);

  const refSplash = useRef<HTMLDivElement>(null);

  const refTitleTop = useRef<HTMLHeadingElement>(null);

  const refShowcaseVideoContainer1 = useRef<HTMLDivElement>(null);
  const refShowcaseVideoContainer2 = useRef<HTMLDivElement>(null);
  const refShowcaseVideoContainer3 = useRef<HTMLDivElement>(null);

  const refMain = useRef<HTMLDivElement>(null);

  const refVideo = useRef<ReactPlayer>(null);
  const refVideoB = useRef<ReactPlayer>(null);
  const refVideoC = useRef<ReactPlayer>(null);

  const refBackground = useRef<HTMLDivElement>(null);

  const refIconContact = useRef<HTMLDivElement>(null);

  const refSectionAbout = useRef<HTMLDivElement>(null);
  const refSectionDesign = useRef<HTMLDivElement>(null);
  const refSectionSystem = useRef<HTMLDivElement>(null);
  const refSectionVision = useRef<HTMLDivElement>(null);
  const refSectionPartners = useRef<HTMLDivElement>(null);
  const refSectionShowcase = useRef<HTMLDivElement>(null);

  const refSectionContact = useRef<HTMLDivElement>(null);

  const refCopyright = useRef<HTMLDivElement>(null);
  const refContainerContact = useRef<HTMLDivElement>(null);

  const cursorRef = useRef({} as Handler);

  const cursorImageRef = useRef({} as Handler);

  const refTextFooter = useRef<HTMLDivElement>(null);

  const refParticle = useRef<HTMLDivElement>(null);

  const refScrollAttention = useRef<HTMLDivElement>(null);

  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(SplitText);

  const [copyrightLeft, setCoypyrightLeft] = useState(0);
  const [videoPathA, setVideoPathA] = useState("video1.jpg");
  const [videoPathB, setVideoPathB] = useState("video2.jpg");
  const [videoPathC, setVideoPathC] = useState("video3.jpg");

  useEffect(() => {
    //scroller.scrollTo("top", { duration: 0, containerId: "main" });

    if (!refSplash.current) return;
    gsap.to(refSplash.current?.childNodes[0].childNodes[0], {
      delay: 0.9,
      ease: "power2.in",
      y: "-200",
    });

    gsap.to(refSplash.current, {
      delay: 1,
      autoAlpha: 0,
    });

    animTop();

    const posContact = refContainerContact.current?.getBoundingClientRect().x;

    ScrollTrigger.create({
      trigger: refSectionSystem.current,
      once: true,
      onEnter: () => {
        setVideoPathA("video1.mp4");
        setVideoPathB("video2.mp4");
        setVideoPathC("video3.mp4");

        ScrollTrigger.getAll().forEach((trigger) => {
          if (
            trigger.vars.id !== "scrollRot" &&
            trigger.vars.id !== "scrollCol"
          ) {
            trigger.refresh();
          }
        });
      },
      scroller: "#main",
    });

    if (refContainerContact.current != null) {
      setCoypyrightLeft(
        refContainerContact.current.getBoundingClientRect().x - 44
      );
    }

    if (refCopyright.current != null) {
      if (posContact != null) {
        refCopyright.current.style.marginLeft = posContact.toString();
      }
    }

    gsap.to(refMain.current, {
      backgroundColor: "rgba(0, 0, 0, 0.6)",

      scrollTrigger: {
        trigger: refSectionAbout.current,
        scrub: 0.1,
        end: "top 15%",
        scroller: "#main",
      },
    });

    gsap.to(refBackground.current, {
      backgroundColor: "rgba(243, 243, 243, 1)",
      scrollTrigger: {
        trigger: refSectionContact.current,
        scrub: 0.01,
        end: "top",
        scroller: "#main",
        invalidateOnRefresh: true,
      },
    });

    gsap.to(refParticle.current, {
      autoAlpha: 0.01,
      //autoAlpha:
      scrollTrigger: {
        trigger: refSectionContact.current,
        scroller: "#main",
        scrub: 0.1,
        end: "top",
      },
    });

    gsap.to([".containerMenu", ".cursor", ".back"], {
      filter: "invert(1)",

      scrollTrigger: {
        trigger: refSectionContact.current,
        scrub: 0.1,
        end: "top",
        scroller: "#main",
      },
    });

    ScrollTrigger.create({
      trigger: refSectionContact.current,
      end: "top",
      once: true,
      scroller: "#main",
      onEnter: () => {
        animIconContact();
      },
    });

    ScrollTrigger.create({
      trigger: refShowcaseVideoContainer1.current,
      scroller: "#main",
      start: "-5% bottom",
      onEnter: () => {
        setPlay(true);
      },
    });

    ScrollTrigger.create({
      trigger: refShowcaseVideoContainer2.current,
      scroller: "#main",
      start: "-5% bottom",
      onEnter: () => {
        setPlayB(true);
      },
    });

    ScrollTrigger.create({
      trigger: refShowcaseVideoContainer3.current,
      scroller: "#main",
      start: "-5% bottom",
      onEnter: () => {
        setPlayC(true);
      },
    });

    /* title */

    animOther(refCatServices, 0.5);
    animOther(refTextVision, 0.5);

    animTitle(refTitleDesign);
    animTitle(refTitleInteractive);
    animTitle(refTitleRemote);
    animTitle(refTitleSystem);
    animTitle(refTitleShowcase);
    animTitle(refTitleTeams);

    animServiceText(refTextDesign);
    animServiceText(refTextInteractive);
    animServiceText(refTextRemote);
    animServiceText(refTextShowcase1);
    animServiceText(refTextShowcase2);
    animServiceText(refTextShowcase3);

    animAbout(refAbout);
    animAbout(refTitleVision);

    animOther(refTextContact, 1);
    animOther(refTextSystem, 0.5);
    animOther(refTextTeams, 0.5);

    animBorder(refBorder1, refBorder2, refBorder3);
    animBorder(refBorderPartners1, refBorderPartners2, refBorderPartners3);

    function updateSize() {
      if (!isMobile) {
        if (arraySplitText.length > 0) {
          arraySplitText.forEach((e) => {
            //e.revert();
          });
        }

        if (refContainerContact.current != null) {
          setCoypyrightLeft(
            refContainerContact.current.getBoundingClientRect().x - 44
          );
        }

        ScrollTrigger.getAll().forEach((trigger) => trigger.refresh());
      }
    }
    window.addEventListener("resize", updateSize);

    ScrollTrigger.getAll().forEach((trigger) => trigger.refresh());

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      tlContact.kill();

      window.removeEventListener("resize", updateSize);

      scroller.scrollTo("top", { duration: 0, containerId: "main" });
    };
  }, []);

  const arraySplitText = new Array<SplitText>();

  function animAbout(ref: any): void {
    const splitText = new SplitText(ref.current, {
      type: "lines",
    });

    arraySplitText.push(splitText);

    const lines = splitText.lines;

    gsap.set(lines, { overflow: "hidden" });

    gsap.from(lines, {
      y: 70,
      autoAlpha: 0,
      duration: 0.8,
      stagger: 0.1,
      scrollTrigger: {
        trigger: ref.current,
        start: "top 70%",
        scroller: "#main",
      },

      onComplete: () => {
        splitText.revert();
      },
    });
  }

  function animTopMotion(ref: any, del: number, isLast: boolean): void {
    if (!ref.current) return;

    gsap.set(ref.current, { overflow: "hidden" });

    gsap.set(ref.current?.parentElement, { perspective: "40vw" });
    gsap.set(ref.current, {
      perspective: "100px",
      transformOrigin: "50% 50%",
    });

    gsap.from(ref.current, {
      delay: del,
      duration: 1,
      autoAlpha: 0,
      y: 100,

      rotateX: -100,
      rotateZ: 15,
      ease: "Circ.easeOut",

      onComplete: () => {
        if (isLast) {
          topLoopOut();
        }
      },
    });
  }

  function animTop(): void {
    animTopMotion(refTopWord1, 1, false);
    animTopMotion(refTopWord2, 1, false);
    animTopMotion(refTopWord3, 1, true);
  }

  function animTitle(ref: any): void {
    const splitText = new SplitText(ref.current, {
      type: "lines",
    });

    arraySplitText.push(splitText);

    const lines = splitText.lines;

    gsap.set(ref.current, { overflow: "hidden" });
    gsap.set(lines, { overflow: "hidden" });

    lines.forEach((c) => {
      gsap.set(c.parentElement, { perspective: "1000px" });
    });

    gsap.set(lines, { perspective: "100px", transformOrigin: "50% 50%" });
    //gsap.set(lines, { transformOrigin: "50% 50%" });

    gsap.from(lines, {
      duration: 0.8,
      autoAlpha: 0,
      y: 100,

      rotateX: -100,
      rotateZ: 15,
      ease: "Circ.easeOut",
      stagger: 0.3,
      scrollTrigger: {
        trigger: ref.current,
        start: "top 70%",
        scroller: "#main",
      },

      onComplete: () => {
        splitText.revert();
      },
    });
  }

  function animServiceText(ref: any): void {
    gsap.set([ref.current.childNodes[0], ref.current.childNodes[1]], {
      overflow: "hidden",
    });

    gsap.from(ref.current.childNodes[0], {
      y: 30,
      autoAlpha: 0,

      stagger: 0.05,
      scrollTrigger: {
        trigger: ref.current,
        start: "top 70%",
        scroller: "#main",
      },
    });

    gsap.from(ref.current.childNodes[1], {
      y: 30,
      autoAlpha: 0,
      delay: 0.2,
      stagger: 0.05,
      scrollTrigger: {
        trigger: ref.current,
        start: "top 70%",
        scroller: "#main",
      },
    });

    if (ref.current.childNodes.length > 2) {
      gsap.from(ref.current.childNodes[2], {
        y: 30,
        autoAlpha: 0,
        delay: 0.2,
        stagger: 0.05,
        scrollTrigger: {
          trigger: ref.current,
          start: "top 70%",
          scroller: "#main",
        },
      });
    }
  }

  function animOther(ref: any, duration: number): void {
    gsap.set(ref.current, { overflow: "hidden" });

    gsap.from(ref.current, {
      y: 50,
      autoAlpha: 0,
      duration: duration,
      stagger: 0.05,
      scrollTrigger: {
        trigger: ref.current,
        start: "top 70%",
        scroller: "#main",
      },
    });
  }

  function topLoopOut() {
    if (refTopWord3.current != null) {
      gsap.to(refTopWord3.current, {
        delay: 2,
        y: -100,
        duration: 0.8,
        ease: "Power4.easeIn",
        onComplete: () => {
          topLoopIn();
          if (topIdx < 2) {
            topIdx++;
          } else {
            topIdx = 0;
          }
          if (refTopWord3.current != null) {
            refTopWord3.current.innerHTML = topWords[topIdx];
          }
        },
      });
    }
  }

  function topLoopIn() {
    if (refTopWord3.current != null) {
      gsap.set(refTopWord3.current, { y: 100 });

      gsap.to(refTopWord3.current, {
        y: 0,
        duration: 0.8,
        ease: "Power4.easeOut",

        onComplete: () => {
          topLoopOut();
        },
      });
    }
  }

  function animIconContact() {
    let _x = 180;

    if (__isMobile) {
      _x = 90;
    }

    if (refIconContact.current != null) {
      if (!gsap.isTweening(refIconContact.current)) {
        tlContact.play();
        tlContact.repeat(1);

        tlContact.fromTo(
          refIconContact.current,
          {
            x: 0,
          },
          {
            x: _x,
            duration: 1,

            ease: "power2.in",

            onComplete: () => {
              gsap.fromTo(
                refIconContact.current,
                {
                  x: -_x,
                },
                {
                  x: 0,
                  duration: 1,

                  ease: "power2.out",
                }
              );
            },
          }
        );
      }
    }
  }

  function animBorder(ref: any, ref2: any, ref3: any): void {
    const dur = 0.8;

    const refArray = [ref, ref2, ref3];
    let _delay = 0;

    refArray.forEach((e) => {
      gsap.to(e.current, {
        width: "100%",
        duration: dur,
        delay: _delay,
        scrollTrigger: {
          trigger: ref.current,
          start: "top 70%",
          scroller: "#main",
        },
      });

      _delay += 0.1;
    });
  }

  function animLine(e: any, isEnter: boolean) {
    gsap.killTweensOf(e);

    if (isEnter) {
      gsap.set(e, { width: 0 });

      gsap.to(e, {
        duration: 0.4,
        ease: "Power4.easeOut",
        width: "100%",
      });
    } else {
      gsap.set(e, { width: "100%" });

      gsap.to(e, {
        duration: 0.3,
        ease: "Circ.easeOut",
        width: "0%",
      });
    }
  }

  const refTop = useRef<HTMLDivElement>(null);
  const refTopWord1 = useRef<HTMLSpanElement>(null);
  const refTopWord2 = useRef<HTMLSpanElement>(null);
  const refTopWord3 = useRef<HTMLSpanElement>(null);

  const refTitleDesign = useRef<HTMLHeadingElement>(null);
  const refTextDesign = useRef<HTMLDivElement>(null);

  const refTitleInteractive = useRef<HTMLHeadingElement>(null);
  const refTextInteractive = useRef<HTMLDivElement>(null);

  const refTitleRemote = useRef<HTMLHeadingElement>(null);
  const refTextRemote = useRef<HTMLDivElement>(null);

  const refTitleSystem = useRef<HTMLHeadingElement>(null);
  const refTextSystem = useRef<HTMLHeadingElement>(null);

  const refTitleShowcase = useRef<HTMLHeadingElement>(null);

  const refTextShowcase1 = useRef<HTMLHeadingElement>(null);
  const refTextShowcase2 = useRef<HTMLHeadingElement>(null);
  const refTextShowcase3 = useRef<HTMLHeadingElement>(null);

  const refTitleTeams = useRef<HTMLHeadingElement>(null);

  const refTitleVision = useRef<HTMLParagraphElement>(null);
  const refTextVision = useRef<HTMLDivElement>(null);

  const refTextTeams = useRef<HTMLParagraphElement>(null);

  const refTextContact = useRef<HTMLDivElement>(null);
  const refTitleContact1 = useRef<HTMLSpanElement>(null);
  const refTitleContact2 = useRef<HTMLSpanElement>(null);

  const refBorder1 = useRef<HTMLDivElement>(null);
  const refBorder2 = useRef<HTMLDivElement>(null);
  const refBorder3 = useRef<HTMLDivElement>(null);

  const refBorderPartners1 = useRef<HTMLDivElement>(null);
  const refBorderPartners2 = useRef<HTMLDivElement>(null);
  const refBorderPartners3 = useRef<HTMLDivElement>(null);

  /* Service */
  const refCatServices = useRef<HTMLSpanElement>(null);

  const refAbout = useRef<HTMLDivElement>(null);

  const MemoedParticle = useMemo(() => {
    return (
      <>
        <Particles ref={particlesRef} />
      </>
    );
  }, []);

  /*
  const MemoedMouseFollowerImage = useMemo(() => {
    return (
      <>
        <MouseFollowerImage ref={cursorImageRef} />
      </>
    );
  }, []);
  */

  function SystemInfoInner(idx: number, id: string) {
    const icon = useRef({} as IconHander);
    const refImage = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (refImage.current != null) {
        if (isMobile || _isMobile) {
          refImage.current.style.visibility = "initial";
        } else {
          refImage.current.style.visibility = "hidden";
        }
      }
    });

    return (
      <>
        <Collapsible
          id={id}
          className={styles.collapsibleClose}
          contentOuterClassName={`${styles.collapsibleOpen}`}
          transitionTime={250}
          easing={"ease-out"}
          trigger={
            <>
              <div className={styles.systemInfo}>
                <h3 className={styles.titleSystemInfo} ref={refTitleTop}>
                  {idx == 0
                    ? t("system-info-1-title")
                    : idx == 1
                    ? t("system-info-2-title")
                    : idx == 2
                    ? t("system-info-3-title")
                    : t("system-info-1-title")}
                </h3>

                <IconPlus
                  className={styles.iconOpen}
                  ref={icon}
                  cursor={cursorRef}
                />
              </div>
            </>
          }
          onOpening={() => {
            icon.current.togglePlus(false);
          }}
          onClosing={() => {
            icon.current.togglePlus(true);
          }}
          onOpen={() => {
            ScrollTrigger.getAll().forEach((trigger) => {
              if (
                trigger.vars.id !== "scrollRot" &&
                trigger.vars.id !== "scrollCol"
              ) {
                trigger.refresh();
              }
            });
          }}
          onClose={() => {
            ScrollTrigger.getAll().forEach((trigger) => {
              if (
                trigger.vars.id !== "scrollRot" &&
                trigger.vars.id !== "scrollCol"
              ) {
                trigger.refresh();
              }
            });
          }}
        >
          <div className={styles.systemInfoInner}>
            <p
              dangerouslySetInnerHTML={
                idx == 0
                  ? { __html: t("system-info-1-text") }
                  : idx == 1
                  ? { __html: t("system-info-2-text") }
                  : idx == 2
                  ? { __html: t("system-info-3-text") }
                  : { __html: t("system-info-1-text") }
              }
              className={`${styles.textSystemInfo}`}
              style={{ pointerEvents: "all" }}
              onPointerEnter={() => {
                cursorRef.current.hideCursor();
              }}
              onPointerLeave={() => {
                cursorRef.current.showCursor();
              }}
            />

            <div className={styles.containerImage} ref={refImage}>
              {!isAndroid && (
                <WobbleImage
                  offset={32}
                  path={
                    idx == 0
                      ? "system1.jpg"
                      : idx == 1
                      ? "system2.jpg"
                      : idx == 2
                      ? "system3.jpg"
                      : "system1.jpg"
                  }
                  size={1.1}
                  canvas={{ x: 303, y: 193 }}
                  ratio={{ x: 10, y: 6.36963696369637 }}
                  mouseFollower={cursorRef}
                  x
                  main={refMain}
                  place="systemInfo"
                  mouseFollowerImage={false}
                />
              )}
              {isAndroid && (
                <Image
                  src={
                    idx == 0
                      ? "/system1.jpg"
                      : idx == 1
                      ? "/system2.jpg"
                      : idx == 2
                      ? "/system3.jpg"
                      : "/system1.jpg"
                  }
                  width="303px"
                  height="193px"
                  alt=""
                />
              )}
            </div>
          </div>
        </Collapsible>
      </>
    );
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta
          name="description"
          content={
            i18n?.language == "ja"
              ? "SHIFT LINKはリアルとバーチャル空間内にインターフェースを構築し、あらゆる機器やデバイス、それを扱う人やサービスをつないでいきます。"
              : "SHIFT LINK creates interfaces for the real and virtual, connecting various equipment, devices, people and services."
          }
        />

        <title>SHIFT LINK</title>
      </Head>

      <div ref={refParticle}>{MemoedParticle}</div>

      <Menu cursor={cursorRef} />
      <MenuMobile
        contact={refContainerContact}
        cursor={cursorRef}
        main={refMain}
      />

      <main
        className="main"
        style={{ position: "relative", zIndex: 2, overflow: "hidden" }}
        ref={refMain}
      >
        <Element name="top">
          {!isAndroid && (
            <section className={styles.sectionTitle}>
              <div className={styles.containerTitle}>
                <MediaQuery maxWidth={1280}>
                  <p dangerouslySetInnerHTML={{ __html: t("top-text") }}></p>
                </MediaQuery>
                <h1 ref={refTop} className={styles.titleTop}>
                  <span ref={refTopWord1}>CONNECT</span>
                  <div>
                    <span ref={refTopWord2}>THE</span>
                    <div style={{ overflow: "hidden" }}>
                      <span className={styles.titleStroke} ref={refTopWord3}>
                        {topWords[topIdx]}
                      </span>
                    </div>
                  </div>
                </h1>
                <MediaQuery minWidth={1281}>
                  <p dangerouslySetInnerHTML={{ __html: t("top-text") }}></p>
                </MediaQuery>
              </div>
            </section>
          )}
          {isAndroid && (
            <div style={{ height: "calc(100vh - 56px)" }}>
              <section className={styles.sectionTitle}>
                <div className={styles.containerTitle}>
                  <MediaQuery maxWidth={1280}>
                    <p dangerouslySetInnerHTML={{ __html: t("top-text") }}></p>
                  </MediaQuery>
                  <h1 ref={refTop} className={styles.titleTop}>
                    <span ref={refTopWord1}>CONNECT</span>
                    <div>
                      <span ref={refTopWord2}>THE</span>
                      <div style={{ overflow: "hidden" }}>
                        <span className={styles.titleStroke} ref={refTopWord3}>
                          {topWords[topIdx]}
                        </span>
                      </div>
                    </div>
                  </h1>
                  <MediaQuery minWidth={1281}>
                    <p dangerouslySetInnerHTML={{ __html: t("top-text") }}></p>
                  </MediaQuery>
                </div>
              </section>
            </div>
          )}
        </Element>
        <div className={styles.scrollAttention} ref={refScrollAttention}>
          (SCROLL)
        </div>

        <div className={styles.background} ref={refBackground}>
          <section className={styles.sectionAbout} ref={refSectionAbout}>
            <p
              className={`${styles.textAbout} ${
                i18n?.language == "ja" && stylesJa.textAbout
              }`}
              ref={refAbout}
              dangerouslySetInnerHTML={{ __html: t("about") }}
            ></p>
          </section>

          <Element name="services">
            <section className={styles.sectionDesign} ref={refSectionDesign}>
              <span className={styles.tag} ref={refCatServices}>
                <IconTagArrow />
                Services
              </span>

              <div className={styles.containerDesign}>
                <h2 ref={refTitleDesign} className={styles.titleDesign}>
                  {t("services-1-title-upper")}
                  <br />
                  {t("services-1-title-lower")}
                </h2>
                <div className={styles.containerImage}>
                  <WobbleImage
                    offset={0}
                    path={"1.jpg"}
                    size={0.95}
                    canvas={{ x: 788, y: 598 }}
                    ratio={{ x: 10, y: 7.58883248730964 }}
                    mouseFollower={cursorRef}
                    main={refMain}
                    place="services"
                    mouseFollowerImage={false}
                  />
                </div>

                <div className={styles.textDesign} ref={refTextDesign}>
                  {i18n?.language == "en" && (
                    <>
                      <p
                        className={`${styles.textDesignMain}`}
                        dangerouslySetInnerHTML={{
                          __html: t("services-1-main"),
                        }}
                      />
                      <p
                        className={`${styles.textDesignSub}`}
                        dangerouslySetInnerHTML={{
                          __html: t("services-1-sub"),
                        }}
                      />
                    </>
                  )}

                  {i18n?.language == "ja" && (
                    <>
                      <p
                        className={`${styles.textDesignMain} ${stylesJa.textDesignMain}`}
                        dangerouslySetInnerHTML={{
                          __html: t("services-1-main"),
                        }}
                      />
                      <p
                        className={`${styles.textDesignSub} ${stylesJa.textDesignSub}`}
                        dangerouslySetInnerHTML={{
                          __html: t("services-1-sub"),
                        }}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className={styles.containerDesign}>
                <h2 ref={refTitleInteractive} className={styles.titleDesign}>
                  {t("services-2-title-upper")}
                  <br />
                  {t("services-2-title-lower")}
                </h2>

                <div className={styles.containerImage}>
                  <WobbleImage
                    offset={32}
                    path={"2.jpg"}
                    size={0.95}
                    canvas={{ x: 788, y: 598 }}
                    ratio={{ x: 10, y: 7.58883248730964 }}
                    mouseFollower={cursorRef}
                    main={refMain}
                    place="services"
                    mouseFollowerImage={false}
                  />
                </div>

                <div className={styles.textDesign} ref={refTextInteractive}>
                  {i18n?.language == "en" && (
                    <>
                      <p
                        className={`${styles.textDesignMain} `}
                        dangerouslySetInnerHTML={{
                          __html: t("services-2-main"),
                        }}
                      />
                      <p
                        className={`${styles.textDesignSub}`}
                        dangerouslySetInnerHTML={{
                          __html: t("services-2-sub"),
                        }}
                      />
                    </>
                  )}
                  {i18n?.language == "ja" && (
                    <>
                      <p
                        className={`${styles.textDesignMain} ${stylesJa.textDesignMain}`}
                        dangerouslySetInnerHTML={{
                          __html: t("services-2-main"),
                        }}
                      />
                      <p
                        className={`${styles.textDesignSub} ${stylesJa.textDesignSub}`}
                        dangerouslySetInnerHTML={{
                          __html: t("services-2-sub"),
                        }}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className={styles.containerDesign}>
                <h2 ref={refTitleRemote} className={styles.titleDesign}>
                  {t("services-3-title-upper")}
                  <br />
                  {t("services-3-title-lower")}
                </h2>
                <div
                  className={`${styles.containerImage} ${styles.remoteControlImage}`}
                >
                  <WobbleImage
                    offset={16}
                    path={"3.jpg"}
                    size={0.95}
                    canvas={{ x: 788, y: 598 }}
                    ratio={{ x: 10, y: 7.58883248730964 }}
                    mouseFollower={cursorRef}
                    main={refMain}
                    place="services"
                    mouseFollowerImage={false}
                  />
                </div>

                <div className={styles.textDesign} ref={refTextRemote}>
                  {i18n?.language == "en" && (
                    <>
                      <p
                        className={`${styles.textDesignMain} `}
                        dangerouslySetInnerHTML={{
                          __html: t("services-3-main"),
                        }}
                      />
                      <p
                        className={`${styles.textDesignSub} `}
                        dangerouslySetInnerHTML={{
                          __html: t("services-3-sub"),
                        }}
                      />
                    </>
                  )}
                  {i18n?.language == "ja" && (
                    <>
                      <p
                        className={`${styles.textDesignMain} ${stylesJa.textDesignMain}`}
                        dangerouslySetInnerHTML={{
                          __html: t("services-3-main"),
                        }}
                      />
                      <p
                        className={`${styles.textDesignSub} ${stylesJa.textDesignSub}`}
                        dangerouslySetInnerHTML={{
                          __html: t("services-3-sub"),
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </section>
          </Element>
          <section
            id="#section-system"
            className={styles.sectionSystem}
            ref={refSectionSystem}
          >
            <MediaQuery minWidth={1281}>
              <MouseFollowerImage
                ref={cursorImageRef}
                sectionSystem={refSectionSystem}
              />
            </MediaQuery>

            <div className={styles.containerImage}>
              <WobbleImage
                offset={32}
                path={"system_top.jpg"}
                rotate={false}
                size={0.95}
                canvas={{ x: 944, y: 648 }}
                ratio={{ x: 10, y: 6.86440677966102 }}
                mouseFollower={cursorRef}
                main={refMain}
                place="system"
                mouseFollowerImage={false}
              />
            </div>

            <h2 ref={refTitleSystem} className={styles.titleSystem}>
              SYSTEM
            </h2>

            {i18n?.language == "en" && (
              <p
                className={`${styles.text}`}
                ref={refTextSystem}
                dangerouslySetInnerHTML={{
                  __html: t("system"),
                }}
              />
            )}
            {i18n?.language == "ja" && (
              <p
                className={`${styles.text} ${stylesJa.text}`}
                ref={refTextSystem}
                dangerouslySetInnerHTML={{
                  __html: "<span>" + t("system") + "</span>",
                }}
              />
            )}

            <div className={styles.containerSystemInfo}>
              <div
                onPointerEnter={() => {
                  if (cursorImageRef.current != null) {
                    if (cursorImageRef.current.animImageCursor) {
                      cursorImageRef.current.animImageCursor(
                        "/system1.jpg",
                        true,
                        refMain.current,
                        0
                      );
                    }
                  }
                }}
                onPointerLeave={() => {
                  if (cursorImageRef.current != null) {
                    if (cursorImageRef.current.animImageCursor) {
                      cursorImageRef.current.animImageCursor(
                        "/system1.jpg",
                        false,
                        refMain.current,
                        0
                      );
                    }
                  }
                }}
              >
                {SystemInfoInner(0, "info0")}

                <div className={styles.border} ref={refBorder1} />
              </div>

              <div
                onPointerEnter={() => {
                  if (cursorImageRef.current != null) {
                    if (cursorImageRef.current.animImageCursor) {
                      cursorImageRef.current.animImageCursor(
                        "/system2.jpg",
                        true,
                        refMain.current,
                        0
                      );
                    }
                  }
                }}
                onPointerLeave={() => {
                  if (cursorImageRef.current != null) {
                    if (cursorImageRef.current.animImageCursor) {
                      cursorImageRef.current.animImageCursor(
                        "/system2.jpg",
                        false,
                        refMain.current,
                        0
                      );
                    }
                  }
                }}
              >
                {SystemInfoInner(1, "info1")}

                <div className={styles.border} ref={refBorder2} />
              </div>

              <div
                onPointerEnter={() => {
                  if (cursorImageRef.current != null) {
                    if (cursorImageRef.current.animImageCursor) {
                      cursorImageRef.current.animImageCursor(
                        "/system3.jpg",
                        true,
                        refMain.current,
                        0
                      );
                    }
                  }
                }}
                onPointerLeave={() => {
                  if (cursorImageRef.current != null) {
                    if (cursorImageRef.current.animImageCursor) {
                      cursorImageRef.current.animImageCursor(
                        "/system3.jpg",
                        false,
                        refMain.current,
                        0
                      );
                    }
                  }
                }}
              >
                {SystemInfoInner(2, "info2")}
                <div className={styles.border} ref={refBorder3} />
              </div>
            </div>
          </section>
          <section className={styles.sectionShowcase} ref={refSectionShowcase}>
            <h2 ref={refTitleShowcase} className={styles.titleShowcase}>
              Showcase
            </h2>

            <div className={styles.showcaseA}>
              <span className={styles.subTitleShowcase}>CASE STUDY 1</span>

              <div
                className={styles.showcaseVideoContainer}
                ref={refShowcaseVideoContainer1}
                onPointerEnter={() => {
                  if (!isPlay) {
                    cursorRef.current.animCursor("Play", true, refMain);
                  } else {
                    cursorRef.current.animCursor("Pause", true, refMain);
                  }
                }}
                onPointerLeave={() => {
                  cursorRef.current.animCursor("Play", false, refMain);
                }}
                onClick={() => {
                  if (refVideo.current != null) {
                  }

                  if (!isPlay) {
                    cursorRef.current.changeLabel("Pause");
                  } else {
                    cursorRef.current.changeLabel("Play");
                  }

                  if (!isPlay) {
                    setPlay(true);
                  } else {
                    setPlay(false);
                  }
                }}
              >
                <ReactPlayer
                  id="a"
                  className={styles.videoPlayer}
                  url={videoPathA}
                  loop={true}
                  muted={true}
                  ref={refVideo}
                  playing={isPlay}
                  onReady={(e) => {
                    const a = e.getInternalPlayer();
                  }}
                  playsinline={true}
                  config={{
                    file: {
                      attributes: {
                        disableRemotePlayback: true,
                        style: {
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        },
                      },
                    },
                  }}
                />
              </div>

              <div ref={refTextShowcase1}>
                <h3 className={styles.subTitle2Showcase}>
                  {t("showcase-1-title-upper")}
                  <br />
                  {t("showcase-1-title-lower")}
                </h3>

                {i18n?.language == "en" && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("showcase-1-text"),
                    }}
                    className={styles.text}
                  ></p>
                )}
                {i18n?.language == "ja" && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: "<span>" + t("showcase-1-text") + "</span>",
                    }}
                    className={styles.text}
                  ></p>
                )}
              </div>
            </div>

            <div className={styles.showcaseB}>
              <span className={styles.subTitleShowcase}>CASE STUDY 2</span>

              <div
                className={styles.showcaseVideoContainer}
                ref={refShowcaseVideoContainer2}
                onPointerEnter={() => {
                  if (!isPlayB) {
                    cursorRef.current.animCursor("Play", true, refMain);
                  } else {
                    cursorRef.current.animCursor("Pause", true, refMain);
                  }
                }}
                onPointerLeave={() => {
                  cursorRef.current.animCursor("Play", false, refMain);
                }}
                onClick={() => {
                  if (refVideoB.current != null) {
                  }

                  if (!isPlayB) {
                    cursorRef.current.changeLabel("Pause");
                  } else {
                    cursorRef.current.changeLabel("Play");
                  }

                  if (!isPlayB) {
                    setPlayB(true);
                  } else {
                    setPlayB(false);
                  }
                }}
              >
                <ReactPlayer
                  id="b"
                  className={styles.videoPlayer}
                  url={videoPathB}
                  loop={true}
                  muted={true}
                  ref={refVideoB}
                  playing={isPlayB}
                  onReady={(e) => {
                    const a = e.getInternalPlayer();
                  }}
                  playsinline={true}
                  config={{
                    file: {
                      attributes: {
                        disableRemotePlayback: true,
                        style: {
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        },
                      },
                    },
                  }}
                />
              </div>

              <div ref={refTextShowcase2}>
                <h3 className={styles.subTitle2Showcase}>
                  {t("showcase-2-title-upper")}
                  <br />
                  {t("showcase-2-title-lower")}
                </h3>

                {i18n?.language == "en" && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("showcase-2-text"),
                    }}
                    className={styles.text}
                  />
                )}
                {i18n?.language == "ja" && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: "<span>" + t("showcase-2-text") + "</span>",
                    }}
                    className={styles.text}
                  />
                )}
              </div>
            </div>

            <div className={styles.showcaseC}>
              <span className={styles.subTitleShowcase}>CASE STUDY 3</span>

              <div
                className={styles.showcaseVideoContainer}
                ref={refShowcaseVideoContainer3}
                onPointerEnter={() => {
                  if (!isPlayC) {
                    cursorRef.current.animCursor("Play", true, refMain);
                  } else {
                    cursorRef.current.animCursor("Pause", true, refMain);
                  }
                }}
                onPointerLeave={() => {
                  cursorRef.current.animCursor("Play", false, refMain);
                }}
                onClick={() => {
                  if (refVideoB.current != null) {
                  }

                  if (!isPlayC) {
                    cursorRef.current.changeLabel("Pause");
                  } else {
                    cursorRef.current.changeLabel("Play");
                  }

                  if (!isPlayC) {
                    setPlayC(true);
                  } else {
                    setPlayC(false);
                  }
                }}
              >
                <ReactPlayer
                  id="c"
                  className={styles.videoPlayer}
                  url={videoPathC}
                  loop={true}
                  muted={true}
                  ref={refVideoC}
                  playing={isPlayC}
                  onReady={(e) => {
                    const a = e.getInternalPlayer();
                  }}
                  playsinline={true}
                  config={{
                    file: {
                      attributes: {
                        disableRemotePlayback: true,
                        style: {
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        },
                      },
                    },
                  }}
                />
              </div>

              <div ref={refTextShowcase3}>
                <h3 className={styles.subTitle2Showcase}>
                  {t("showcase-3-title-upper")}
                  <br />
                  {t("showcase-3-title-lower")}
                </h3>

                {i18n?.language == "en" && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("showcase-3-text"),
                    }}
                    className={styles.text}
                  />
                )}
                {i18n?.language == "ja" && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: "<span>" + t("showcase-3-text") + "</span>",
                    }}
                    className={styles.text}
                  />
                )}
                <p className={styles.showcaseLink}>
                  <Link href={"https://media-facade.shiftlink.tech/"} passHref>
                    <a target={"_blank"}>Link to the Media Facade Simulator</a>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <Element name="vision">
            <section className={styles.sectionVision} ref={refSectionVision}>
              <span className={styles.tag} ref={refTextVision}>
                <IconTagArrow />
                Our Future Vision
              </span>

              <p
                className={`${styles.textVision} ${
                  i18n?.language == "ja" && stylesJa.textVision
                }`}
                ref={refTitleVision}
                dangerouslySetInnerHTML={{
                  __html: t("future-vision"),
                }}
              />
            </section>
          </Element>
          <Element name="teams">
            <section
              className={styles.sectionPartners}
              ref={refSectionPartners}
            >
              <h2 ref={refTitleTeams} className={styles.titlePartners}>
                TEAMS
              </h2>

              <p
                ref={refTextTeams}
                style={{ lineHeight: "1.5em" }}
                dangerouslySetInnerHTML={{
                  __html: t("teams-text"),
                }}
                className={styles.text}
              />

              <div className={styles.borderPartners} ref={refBorderPartners1} />

              <MediaQuery minWidth={1281}>
                <Link
                  href={
                    i18n?.language == "en"
                      ? "https://theshift.tokyo/en/"
                      : "https://theshift.tokyo/"
                  }
                  passHref
                >
                  <a target="_blank">
                    <div className={styles.containerPartners}>
                      <div
                        onPointerEnter={() => {
                          cursorRef.current.animCursor(
                            "Visit\nWebsite",
                            true,
                            refMain
                          );
                        }}
                        onPointerLeave={() => {
                          cursorRef.current.animCursor(
                            "Visit\nWebsite",
                            false,
                            refMain
                          );
                        }}
                      >
                        <div>
                          <h3 className={styles.subTitlePartners}>THE SHIFT</h3>
                          <span>{t("teams-shift-role")}</span>
                          <MediaQuery maxWidth={1280}>
                            <Link
                              href={
                                i18n?.language == "en"
                                  ? "https://theshift.tokyo/en/"
                                  : "https://theshift.tokyo/"
                              }
                              passHref
                            >
                              <a target="_blank">
                                <span className={styles.url}>Website</span>
                              </a>
                            </Link>
                          </MediaQuery>
                        </div>
                        <div>
                          {i18n?.language == "en" && (
                            <p className={`${styles.textTeams} `}>
                              {t("teams-shift")}
                            </p>
                          )}
                          {i18n?.language == "ja" && (
                            <p
                              className={`${styles.textTeams} ${stylesJa.textTeams}`}
                            >
                              {t("teams-shift")}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </a>
                </Link>
              </MediaQuery>

              <MediaQuery maxWidth={1280}>
                <div className={styles.containerPartners}>
                  <div>
                    <div>
                      <h3 className={styles.subTitlePartners}>THE SHIFT</h3>
                      <span>{t("teams-shift-role")}</span>
                      <Link
                        href={
                          i18n?.language == "en"
                            ? "https://theshift.tokyo/en/"
                            : "https://theshift.tokyo/"
                        }
                        passHref
                      >
                        <a target="_blank" className={styles.url}>
                          <span>Website</span>
                        </a>
                      </Link>
                    </div>
                    <div>
                      {i18n?.language == "en" && (
                        <p className={`${styles.textTeams} `}>
                          {t("teams-shift")}
                        </p>
                      )}
                      {i18n?.language == "ja" && (
                        <p
                          className={`${styles.textTeams} ${stylesJa.textTeams}`}
                        >
                          {t("teams-shift")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </MediaQuery>

              <div className={styles.borderPartners} ref={refBorderPartners2} />

              <MediaQuery minWidth={1281}>
                <Link
                  href={
                    i18n?.language == "en"
                      ? "https://aircord.co.jp/en/"
                      : "https://aircord.co.jp/"
                  }
                  passHref
                >
                  <a target="_blank">
                    <div className={styles.containerPartners}>
                      <div
                        onPointerEnter={() => {
                          cursorRef.current.animCursor(
                            "Visit\nWebsite",
                            true,
                            refMain
                          );
                        }}
                        onPointerLeave={() => {
                          cursorRef.current.animCursor(
                            "Visit\nWebsite",
                            false,
                            refMain
                          );
                        }}
                      >
                        <div>
                          <h3
                            className={`${styles.subTitlePartners} ${styles.ac}`}
                          >
                            <LogoAircord />
                          </h3>
                          <span>{t("teams-aircord-role")}</span>
                        </div>
                        <div>
                          <p
                            className={`${styles.textTeams} ${
                              i18n?.language == "ja" && stylesJa.textTeams
                            }`}
                          >
                            {t("teams-aircord")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </Link>
              </MediaQuery>

              <MediaQuery maxWidth={1280}>
                <div className={styles.containerPartners}>
                  <div>
                    <div>
                      <h3 className={`${styles.subTitlePartners} ${styles.ac}`}>
                        <LogoAircord />
                      </h3>
                      <span>{t("teams-aircord-role")}</span>
                      <Link
                        href={
                          i18n?.language == "en"
                            ? "https://aircord.co.jp/en/"
                            : "https://aircord.co.jp/"
                        }
                        passHref
                      >
                        <a className={styles.url} target="_blank">
                          <span>Website</span>
                        </a>
                      </Link>
                    </div>
                    <div>
                      <p
                        className={`${styles.textTeams} ${
                          i18n?.language == "ja" && stylesJa.textTeams
                        }`}
                      >
                        {t("teams-aircord")}
                      </p>
                    </div>
                  </div>
                </div>
              </MediaQuery>

              <div className={styles.borderPartners} ref={refBorderPartners3} />
            </section>
          </Element>

          <Element name="contact">
            <section
              className={styles.sectionContact}
              ref={refSectionContact}
              id="#contact"
            >
              <div
                className={styles.containerContact}
                ref={refContainerContact}
              >
                <p
                  ref={refTextContact}
                  className={`${styles.textContact} `}
                  dangerouslySetInnerHTML={{ __html: t("contact-text") }}
                ></p>
                <Link href={"mailto:info@theshift.tokyo"} passHref>
                  <a>
                    <h2
                      className={`${styles.titleContact} `}
                      onPointerEnter={() => {
                        cursorRef.current.animCursor("Email Us", true, refMain);
                      }}
                      onPointerLeave={() => {
                        cursorRef.current.animCursor(
                          "Email Us",
                          false,
                          refMain
                        );
                      }}
                    >
                      <span ref={refTitleContact1}>CONNECT</span>
                      <div style={{ pointerEvents: "none" }}>
                        <span ref={refTitleContact2}>WITH US</span>{" "}
                        <div style={{ overflow: "hidden" }}>
                          <div ref={refIconContact}>
                            <IconContact />
                          </div>
                        </div>
                      </div>
                    </h2>
                  </a>
                </Link>
                <MediaQuery maxWidth={1280}>
                  <div
                    style={{
                      color: "#000",
                      position: "relative",
                      top: "5vh",
                      fontWeight: 300,
                    }}
                    className={styles.contactMobileCopyright}
                  >
                    © 2022 The Shift, Inc. All rights reserved.
                  </div>
                </MediaQuery>
              </div>
            </section>
          </Element>

          <div
            ref={refTextFooter}
            className={`${styles.footer} ${styles.textFooter}`}
          >
            <MediaQuery minWidth={1281}>
              <div
                ref={refCopyright}
                style={{
                  marginLeft: copyrightLeft,
                }}
              >
                © 2022 The Shift, Inc. All rights reserved.
              </div>
            </MediaQuery>
            <div
              onPointerEnter={(e) => {
                if (!_isMobile) {
                  animLine(e.currentTarget.childNodes[1], true);
                  cursorRef.current.hideCursor();
                }
              }}
              onPointerLeave={(e) => {
                if (!_isMobile) {
                  animLine(e.currentTarget.childNodes[1], false);
                  cursorRef.current.showCursor();
                }
              }}
            >
              <Link href={"https://www.instagram.com/theshift.tokyo/"} passHref>
                <a target={"_blank"}>INSTAGRAM</a>
              </Link>
              <span className={styles.line} />
            </div>
            <div
              onPointerEnter={(e) => {
                if (!_isMobile) {
                  animLine(e.currentTarget.childNodes[1], true);
                  cursorRef.current.hideCursor();
                }
              }}
              onPointerLeave={(e) => {
                if (!_isMobile) {
                  animLine(e.currentTarget.childNodes[1], false);
                  cursorRef.current.showCursor();
                }
              }}
            >
              <Link href={"https://twitter.com/theshift_tokyo"} passHref>
                <a target={"_blank"}>TWITTER</a>
              </Link>
              <span className={styles.line} />
            </div>
          </div>
        </div>
      </main>
      <IconBack
        main={refMain.current}
        contact={refSectionContact.current}
        cursor={cursorRef}
      />
      <MouseFollower ref={cursorRef} />

      <div
        ref={refSplash}
        style={{
          top: 0,
          whiteSpace: "nowrap",
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#000",
          width: "100vw",
          height: "100%",
          zIndex: 1000,
          pointerEvents: "none",
        }}
      >
        <div style={{ overflow: "hidden" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconLogo />
          </div>
        </div>
      </div>
    </>
  );
};

export const getStaticProps = async ({ locale }: any) => ({
  props: {
    ...(await serverSideTranslations(locale, ["common"])),
  },
});

export default Top;
